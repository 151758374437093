import * as React from "react"
const Close = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.475}
    height={18.475}
    {...props}
  >
    <g fill="none" stroke="#000" strokeWidth={3.5} data-name="Group 19935">
      <path d="m17.237 1.237-16 16" data-name="Line 1998" />
      <path d="m1.237 1.237 16 16" data-name="Line 1999" />
    </g>
  </svg>
)
export default Close