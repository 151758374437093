import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './services/auth';
import { UserState } from './services/userContext';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { SocketProvider } from './services/EC2SocketContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter basename="/bins-app">
    <SocketProvider>
      <AuthContextProvider>
        <UserState>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </UserState>
      </AuthContextProvider>
    </SocketProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

serviceWorkerRegistration.register();


reportWebVitals();
