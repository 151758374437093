import React from 'react';
import './styles.scss'; // Ensure the SCSS file is named bin_BottomModal.scss and located in the same directory
import { IoIosClose } from "react-icons/io";

const Modal = ({ bodyClassName, isOpen, title, onClose, backdrop, closable, className, style, children }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className={`bin_backdrop ${backdrop ? 'bin_backdrop--active' : ''} ${className}`} >
            <div
                className={`bin_modal ${bodyClassName}`}
                style={style}
                onClick={e => e.stopPropagation()} // Prevent click from closing modal
            >
                <div className='d-flex justify-content-between'>
                    <div className='bin_modal_title'>{title}</div>
                   {closable &&  <div onClick={() => {onClose()}}>
                        <IoIosClose size={30} />
                    </div> }
                </div>

                {children}
            </div>
        </div>
    );
};

export default Modal;
