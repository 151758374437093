import React from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { Header, PieChart, WeeklyProgressBars } from '../../components';


const Notifications = () => {

    return (
        <div className=' d-flex justify-content-between flex-column p-0 '>
            <Header
                label={"Notifications"}
                right={<BsThreeDotsVertical />}
            />

            <div className='bin_page-content p-0'>
                <div className='d-flex h-100 flex-column justify-content-between'>
                    <div className='d-flex h-100 flex-column'>
                     
                           notification
             
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default Notifications;


