
import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components';
import "./styles.scss";
import { BUTTON_ACTION, getRequiredBinButtons } from '../../../../utils/binHelper';
import { GoDotFill } from 'react-icons/go';

const Footer = ({
    bin,
    onUpdateLevel,
    onRequestPickup,
    onRequestBinHistory,
    onTrackBin,
    currentZoneBins,
    onCancelPickup,
    toggleBin
}) => {

    const [visibleButtons, setVisibleButtons] = useState([]);

    useEffect(() => {
        const requiredButtons = getRequiredBinButtons(bin);
        setVisibleButtons(requiredButtons)
    }, [bin])

    return <div className='bin_detail-footer' >
        <div className='mx-4'>
            {visibleButtons.includes(BUTTON_ACTION.UPDATE_LEVEL) && <Button className="mb-1" onClick={() => { onUpdateLevel() }}>Update Level</Button>}
            {visibleButtons.includes(BUTTON_ACTION.TRACK_BIN) && <Button className="mb-1" onClick={() => {onTrackBin() }}>Track Bin</Button>}
            {visibleButtons.includes(BUTTON_ACTION.REQUEST_PICKUP) && <Button className="mb-1" onClick={() => { onRequestPickup() }}>Request Pickup</Button>}
            {visibleButtons.includes(BUTTON_ACTION.CANCEL_PICKUP) && <Button className="mb-1" onClick={() => { onCancelPickup() }}>Cancel Pickup</Button>}
            {visibleButtons.includes(BUTTON_ACTION.HISTORY) && <Button className="mb-1" onClick={() => { onRequestBinHistory() }}>Bin History</Button>}
            <div className='d-flex justify-content-center'>
                {currentZoneBins?.map((currentBin, index) => {
                    return <GoDotFill onClick={() => { toggleBin(currentBin)}} size={20} className="mt-4" color={`${currentBin?.name == bin?.name ? "#2B2D2F" : "#939292"}`}  />  
                })}
            </div>
        </div>
    </div>
};

export default Footer;
