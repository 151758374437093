//@ts-check
// @ts-nocheck

import React, { useCallback, useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  BUTTON_ACTION,
  getBinIconByLevel,
  getRequiredBinButtons,
} from "../../../utils/binHelper";
import { BinsCardLabel, BinInfoTile, BinTitle } from "./index.js";
import { Button } from "../../../components";
import "./style.scss";
import { useSocket } from "../../../hooks/useSocket";
import { PickupEventType, PickupStatus, SocketEvent, SocketResponse } from "../../../utils/types";
import { SocketClient, SocketRoom } from "../../../utils/constants";
import { logger } from "../../../utils/logger";

const BinsCard = ({
  bin,
  onUpdateLevel,
  onTrackBin,
  onDetailClick,
  onRequestPickup,
  onCancelPickup,
  onActionMenuClick,
  onChangeSocketEvent,
  onRequestBinHistory,
}) => {
  const iconWidth = 50;
  const iconHeight = 50;

  const [visibleButtons, setVisibleButtons] = useState([]);
  const [currentBin, setCurrentBin] = useState({});

  const updateBinEvents = useCallback((event: SocketResponse): void => {
    if (currentBin?.name === event?.newPickup?.bin?.name) {
      const updatedBin = {
        ...currentBin,
        lastPickup: event?.newPickup,
      };
      setCurrentBin(updatedBin);
      onChangeSocketEvent(updatedBin);
    }
  }, [currentBin, onChangeSocketEvent]);

  const updateBinEventsByRefNo = useCallback((event: SocketResponse): void => {
    if (currentBin?.lastPickup?.pickupRef === event?.pickupReferenceNumber) {
      const currentEvents = [];
      let eventFound = false;
      let isBinDelivered = false;

      currentBin?.lastPickup?.events?.forEach(
        (currentAllEvent) => {
          if (currentAllEvent?.type === event?.type) {
            eventFound = true;
            if (event?.type === PickupEventType.DELIVER ) {
              isBinDelivered = true;
            }
            currentEvents?.push({ ...event, zone: event?.location });
          }
          if (!eventFound) {
            currentEvents?.push(currentAllEvent);
          }
        }
      );

      if (!eventFound) {
        if (event?.type === PickupEventType.DELIVER) {
          isBinDelivered = true;
        }
        currentEvents?.push({ ...event, zone: event?.location });
      }

      const updatedBin = {
        ...currentBin,

        lastPickup: {
          ...currentBin?.lastPickup,
          ...(isBinDelivered && { status:  PickupStatus.COMPLETE }),
          events: currentEvents,
        },
      };
      setCurrentBin(updatedBin);
      onChangeSocketEvent(updatedBin);
    }
  }, [currentBin, onChangeSocketEvent]) 

  // Scrap bin sockets
  const socketHandler = useCallback(
    (socketData: SocketResponse): void => {
      logger.debug("[BinCard] component", {socketData})
      if (socketData.socketEvent === SocketEvent.PICKUP_CHANGE) {
        updateBinEventsByRefNo(socketData);
      } else if (socketData.socketEvent === SocketEvent.NEW_PICKUP) {
        updateBinEvents(socketData);
      }
    },
    [updateBinEvents, updateBinEventsByRefNo]
  );

  useSocket<SocketResponse>(
    SocketRoom.SCRAP_BINS,
    socketHandler,
    SocketClient.ADMIN
  );

  useEffect(() => {
    const requiredButtons = getRequiredBinButtons(currentBin);
    setVisibleButtons(requiredButtons);
  }, [currentBin]);

  useEffect(() => {
    setCurrentBin(bin);
  }, [bin]);

  return (
    <div className="bin_page-card">
      <div onClick={onDetailClick} className="bin_card-details">
        <div className={`d-flex bin_page_card-header  justify-content-between`}>
          <BinTitle bin={currentBin} />
          <div
            onClick={(e) => {
              e.stopPropagation();
              onActionMenuClick();
            }}
          >
            <BsThreeDotsVertical size={28} />
          </div>
        </div>

        <div className="py-4">
          <BinsCardLabel bin={currentBin} />
        </div>
        <div className="py-4">
          <BinInfoTile
            icon={getBinIconByLevel(
              currentBin?.binLevel,
              iconWidth,
              iconHeight
            )}
            value={`${currentBin?.binLevel}%`}
            label="Bin Level:"
          />
        </div>
      </div>

      <div className="bin_card-buttons">
        {visibleButtons.includes(BUTTON_ACTION.UPDATE_LEVEL) && (
          <Button
            className=" "
            onClick={() => {
              onUpdateLevel();
            }}
          >
            {" "}
            Update Level{" "}
          </Button>
        )}
        {visibleButtons.includes(BUTTON_ACTION.TRACK_BIN) && (
          <Button
            className=" "
            onClick={() => {
              onTrackBin();
            }}
          >
            {" "}
            Track Bin{" "}
          </Button>
        )}
        {visibleButtons.includes(BUTTON_ACTION.REQUEST_PICKUP) && (
          <Button
            className=" "
            onClick={() => {
              onRequestPickup();
            }}
          >
            {" "}
            Request Pickup{" "}
          </Button>
        )}
        {visibleButtons.includes(BUTTON_ACTION.CANCEL_PICKUP) && (
          <Button
            className=" "
            onClick={() => {
              onCancelPickup();
            }}
          >
            {" "}
            Cancel Pickup{" "}
          </Button>
        )}
        {visibleButtons.includes(BUTTON_ACTION.HISTORY) && (
          <Button
            className=" "
            onClick={() => {
              onRequestBinHistory();
            }}
          >
            {" "}
            Bin History{" "}
          </Button>
        )}
      </div>
    </div>
  );
};

export default BinsCard;
