import * as React from "react";

const MissingAlert = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.694}
    height={16.094}
    viewBox="0 0 47.694 42.094"
    {...props}
  >
    <g
      id="Group_58189"
      data-name="Group 58189"
      transform="translate(0.257 -0.406)"
    >
      <g id="Polygon_10" data-name="Polygon 10" fill="#F858B4">
        <path
          d="M 41.92743682861328 41.24999618530273 L 41.92742538452148 41.24999618530273 L 5.252076625823975 41.24999618530273 C 3.707406520843506 41.24999618530273 2.327826499938965 40.44923782348633 1.561686515808105 39.10796737670898 C 0.7955464720726013 37.76668548583984 0.8066064715385437 36.17158508300781 1.591266512870789 34.84106826782227 L 19.92893600463867 3.746746778488159 C 20.70112609863281 2.437386751174927 22.06964683532715 1.655686736106873 23.58974647521973 1.655686736106873 C 25.10983657836914 1.655686736106873 26.47836685180664 2.437386751174927 27.25055694580078 3.746736764907837 L 45.58822631835938 34.84106826782227 C 46.37284469604492 36.17151641845703 46.38386535644531 37.76658630371094 45.61770629882812 39.10789489746094 C 44.85154724121094 40.4492073059082 43.47200775146484 41.24999618530273 41.92743682861328 41.24999618530273 Z"
          stroke="none"
        />
        <path
          d="M 23.58974266052246 2.905681610107422 C 22.58785629272461 2.905681610107422 21.58597183227539 3.397697448730469 21.00564575195312 4.381725311279297 L 2.667976379394531 35.47604751586914 C 1.488567352294922 37.47591781616211 2.930328369140625 39.99999618530273 5.252067565917969 39.99999618530273 L 41.92741775512695 39.99999618530273 C 44.24812698364258 40.00213623046875 45.69092559814453 37.47591781616211 44.51151657104492 35.47604751586914 L 26.17384719848633 4.381725311279297 C 25.5935173034668 3.397697448730469 24.59162902832031 2.905681610107422 23.58974266052246 2.905681610107422 M 23.58974647521973 0.4056777954101562 C 25.55691719055176 0.4056777954101562 27.32794570922852 1.41729736328125 28.32725715637207 3.11175537109375 L 46.66492462158203 34.20608520507812 C 47.68033599853516 35.9278564453125 47.69461822509766 37.99208831787109 46.70311737060547 39.72787475585938 C 45.71161651611328 41.46368789672852 43.92631530761719 42.49999618530273 41.92742538452148 42.49999618530273 L 5.252067565917969 42.49999618530273 C 3.253086090087891 42.49999618530273 1.467746734619141 41.46371841430664 0.4762763977050781 39.72795867919922 C -0.5152053833007812 37.9921875 -0.5008926391601562 35.92793655395508 0.514556884765625 34.20608520507812 L 18.85223579406738 3.111766815185547 C 19.85151672363281 1.41729736328125 21.62255668640137 0.4056777954101562 23.58974647521973 0.4056777954101562 Z"
          stroke="none"
          fill="#2b2d2f"
        />
      </g>
      <path
        id="Path_15008"
        data-name="Path 15008"
        d="M6.692-23.162H3.562L2.908-35.693H7.346ZM2.856-18.775A2.15,2.15,0,0,1,3.434-20.4a2.341,2.341,0,0,1,1.68-.552,2.274,2.274,0,0,1,1.648.564,2.145,2.145,0,0,1,.584,1.616,2.145,2.145,0,0,1-.59,1.6,2.233,2.233,0,0,1-1.642.584,2.3,2.3,0,0,1-1.667-.571A2.136,2.136,0,0,1,2.856-18.775Z"
        transform="translate(18.278 51.091)"
        fill="#2b2d2f"
        stroke="rgba(0,0,0,0)"
        strokeWidth={1}
      />
    </g>
  </svg>
);
export default MissingAlert;
