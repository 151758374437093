import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import {
  DefaultMarker,
  Hourglass,
  Info,
  InfoDisabled,
  PickupIcon,
  Scrap,
} from "../../../assets/images/index.js";
import { Header, LabelWithLine } from "../../../components/index.js";
import {
  BIN_STATUS,
  getBinIconByLevel,
  getCommonBinStatus,
  getDurationAgoWithoutParentheses,
} from "../../../utils/binHelper.jsx";
import Footer from "./Footer/index.js";
import { BinInfoTile, BinTitle, BinsCardLabel } from "./index.js";
import TruckIcon from "../../../assets/images/Bins/Truck.jsx";

const SwipeThreshold = 50;

const BinsCardDetails = ({
  onActionMenuClick,
  onUpdateLevel,
  onTrackBin,
  onRequestPickup,
  onCancelPickup,
  onRequestBinHistory,
  toggleBin,
  bin = {},
  currentZoneBins = [],
  onClose,
}) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isSwipeRight = distance > 0;
    if (Math.abs(distance) > SwipeThreshold) {
      if (isSwipeRight) {
        currentZoneBins?.forEach((currentBin, index) => {
          if (currentBin?.name === bin?.name) {
            toggleBin(
              currentZoneBins[
                currentZoneBins?.length - 1 === index ? index : index + 1
              ],
            );
          }
        });
      } else {
        currentZoneBins?.forEach((currentBin, index) => {
          if (currentBin?.name === bin?.name) {
            toggleBin(currentZoneBins[index === 0 ? 0 : index - 1]);
          }
        });
      }
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  const iconWidth = 40;
  const iconHeight = 40;

  const binLocation = bin?.lastLocation?.lat
    ? bin.lastLocation.zone ??
      `${bin.lastLocation.lat}, ${bin.lastLocation.long}`
    : "-";

  const isBinMissing = getCommonBinStatus(bin) === BIN_STATUS.BIN_MISSING;

  const binAttributes = [
    {
      name: "Bin Level:",
      value: `${bin?.binLevel ?? "-"}%`,
      subValue: "",
      icon: getBinIconByLevel(bin.binLevel, iconWidth, iconHeight),
    },
    {
      name: "Last Seen Location:",
      value: binLocation,
      subValue: "",
      icon: (
        <DefaultMarker
          width={iconWidth}
          height={iconHeight}
          fill={isBinMissing ? "#d54a4a" : undefined}
          stroke={isBinMissing ? "#aa2727" : undefined}
        />
      ),
    },
    {
      name: "Scrap Type:",
      value: `${bin?.scrapType ?? "-"}`,
      subValue: "",
      icon: <Scrap width={iconWidth} height={iconHeight} />,
    },
    {
      name: "Truck:",
      subValue: `${bin?.lastPickup?.operator?.firstName ?? " -"} ${
        bin?.lastPickup?.operator?.lastName ?? ""
      }`,
      value: `${bin?.lastPickup?.truckName ?? "-"} `,
      icon: <TruckIcon width={45} height={45} />,
    },
    {
      name: "Last Emptied:",
      subValue: "",
      value: getDurationAgoWithoutParentheses(bin?.lastEmptiedTime),
      icon: <Hourglass width={iconWidth} height={iconHeight} />,
    },
  ];

  const otherAttributes = [
    {
      name: "Pickup Point:",
      value: `${bin?.pickupPoint ?? "-"}`,
      subValue: "",
      icon: <PickupIcon width={iconWidth} height={iconHeight} />,
    },
    {
      name: "Dump Point:",
      value: `${bin?.dumpPoint ?? "-"}`,
      subValue: "",
      icon: <PickupIcon width={iconWidth} height={iconHeight} />,
    },
    {
      name: "Drop-off Point:",
      value: `${bin?.dropoffPoint ?? "-"}`,
      subValue: "",
      icon: <PickupIcon width={iconWidth} height={iconHeight} />,
    },
  ];

  return (
    <>
      {/* // header // */}
      <Header
        className="bin_header-border"
        left={
          <div
            onClick={() => {
              onClose();
            }}
            className="bin_header-content d-flex align-items-center"
          >
            <MdOutlineKeyboardArrowLeft size={28} />
            Bin Details
          </div>
        }
      />

      {/* // body // */}
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className="bin_detail_page-card"
      >
        <div
          className={`d-flex bin_page_card-header flex-row w-100 justify-content-between`}
        >
          <BinTitle bin={bin} />

          <div onClick={onActionMenuClick}>
            <BsThreeDotsVertical size={28} />
          </div>
        </div>

        <div className="py-4 ">
          {" "}
          <BinsCardLabel bin={bin} />
        </div>

        {binAttributes.map((item, index) => {
          return (
            <div key={index} className="py-4">
              <BinInfoTile
                icon={item.icon}
                value={item.value}
                subValue={item.subValue}
                label={item.name}
              />
            </div>
          );
        })}

        <div className="py-4">
          <BinInfoTile
            icon={
              bin?.hazards ? (
                <Info width={iconWidth} height={iconHeight} />
              ) : (
                <InfoDisabled width={iconWidth} height={iconHeight} />
              )
            }
            value={bin?.hazards ?? "-"}
            subValue={""}
            label={"Hazards"}
          />
        </div>

        <LabelWithLine title={"Other Details "} />
        {otherAttributes.map((item, index) => {
          return (
            <div key={index} className="py-4">
              <BinInfoTile
                icon={item.icon}
                value={item.value}
                subValue={item.subValue}
                label={item.name}
              />
            </div>
          );
        })}
      </div>

      {/* // footer // */}
      <Footer
        bin={bin}
        currentZoneBins={currentZoneBins}
        onUpdateLevel={onUpdateLevel}
        onTrackBin={onTrackBin}
        toggleBin={toggleBin}
        onRequestBinHistory={onRequestBinHistory}
        onRequestPickup={onRequestPickup}
        onCancelPickup={onCancelPickup}
      />
    </>
  );
};

export default BinsCardDetails;
