import React, { useContext, useState } from 'react';
import { Button, toast } from '../../../../components';
import { TextArea } from '../../../../components/inputs';
import "./styles.scss";
import { API_METHODS, ENDPOINTS } from '../../../../utils/constants';
import { UserContext } from '../../../../services/userContext';

const AddHazards = ({ onSave, type, bin }) => {
    const { ApiHandler } = useContext(UserContext);
    const [hazard, setHazard] = useState(type == "Update" ? bin?.hazards : "");

    const updateHazards = async () => {
        try {
            const response = await ApiHandler ({
                reqParam: {
                    hazards: hazard,
                    binName: bin.name
                },
                method: API_METHODS.PATCH,
                endPoint: ENDPOINTS.updateBin,
            });
            if (response.status === 200) {
                onSave();
                toast.success('Details successfully updated');
            }
        } catch (error) {
        }
    }

    return <div className='d-flex flex-column justify-content-between h-100'  >
        <div className='d-flex flex-column flex-1'>
            <TextArea
                value={hazard}
                placeholder={"Tap to type your hazards"}
                numOfLines = {20}
                onChange={(v) => { setHazard(v)}}
            />
        </div>

        <Button 
            isDisabled={hazard == bin?.hazards}
        className={"mb-5"}
         onClick={() => { updateHazards() }}
         > Save {type == "Update" ? " Changes "  : "" }
         </Button>
    </div>
};

export default AddHazards;
