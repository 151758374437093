import { useEffect, useState } from 'react';
import { SocketRoom, WebSocketURL } from '../utils/constants';
import { logger } from '../utils/logger';

export const useSocket = <SocketResponse>(
  socketRoom: SocketRoom,
  handleResponseCallback: (responseData: SocketResponse) => void,
  clientName?: string,
) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    const connect = (url: string): WebSocket => {
      logger.debug("[useSocket] [useEffect]: New socket connected", { url, clientName, socketRoom })
      const newSocket = new WebSocket(url);
      setSocket(newSocket);
      newSocket.onerror = (error: Event) => {
        logger.error('WebSocket error:', error);
      };
      return newSocket;
    };

    let socketUrl = WebSocketURL + socketRoom;
    if (clientName) socketUrl += `&clientName=${clientName}`;
    let createdSocket: WebSocket = connect(socketUrl);
    const heartbeatInterval: NodeJS.Timeout = setInterval(() => {
      if (createdSocket.readyState === WebSocket.OPEN) {
        createdSocket.send('heartbeat');
      } else {
        createdSocket = connect(socketUrl);
      }
    }, 25000);

    return () => {
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
      createdSocket.close();
    };
  }, [clientName, socketRoom]);

  useEffect(() => {
    logger.debug("[useSocket] [useEffect]: handle message", { socket })
    if (socket) {
      socket.onmessage = (event: MessageEvent) => {
        const message = event?.data;
        const responseData: SocketResponse = JSON.parse(message);
        handleResponseCallback(responseData);
      };
    }
  }, [handleResponseCallback, socket]);

};
