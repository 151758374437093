import React from "react";
import { isMacPlatform } from "../../utils/binHelper";

const DisplayInstallationInstructions = () => {
  // This line of code checks if the PWA is running in standalone mode.
  // Standalone mode means the app is installed and is being run outside of a browser, similar to a native app.
  // ref: https://web.dev/learn/pwa/detection
  const isPwaInstalled = window.matchMedia('(display-mode: standalone)').matches;

  if (isPwaInstalled) return ''
  return (
    <h4 className="mt-4 bin_login_checkbox">
      {
        isMacPlatform() ? (
            "Add this app to your Home Screen by tapping the Share button at the bottom center of your screen. Then, select Add to Home Screen."
        ) : (
          "* Add this app to your Home Screen by clicking the three dots in your browser. From the dropdown menu, select Install App."
        )
      }
    </h4>
  );
};

export default DisplayInstallationInstructions;
