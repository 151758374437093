// components/Layout.js
import React from 'react';
import BottomNavigation from './BottomNavigation';
import LeftNavigation from './LeftNavigation';

const Layout = ({ children }) => (
    <div className="container-fluid">
        <div className="row position-relative">
            {/* <div className="d-md-flex d-none">
                <LeftNavigation />
            </div> */}
            <div className="w-100">{children}</div>
            <div className=" d-flex">
                <BottomNavigation />
            </div>
        </div>
    </div>
);

export default Layout;
