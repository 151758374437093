import React from 'react';
import "./styles.scss";
import { getBinColorByLevel } from '../../../../utils/binHelper';

const BinLevelTile = ({ level, isSelected, onClick }) => {
    return <div onClick={onClick} >
        <div className={`bin_level-circle-view ${isSelected ? "bin_level-circle-view-active" : "" } `}>
            <div style={{ backgroundColor: getBinColorByLevel(isSelected ? level : -1)?.backgroundColor, borderColor: getBinColorByLevel(isSelected ? level : -1)?.borderColor }} className={`bin_level-bigCircle`} />
            <div style={{ backgroundColor: getBinColorByLevel(isSelected ? level : -1)?.borderColor, }} className={`bin_level-circle `} />

            <div className='bin_level-title'>{level == 0 ? "Empty" : `${level} %`}</div>
        </div>
    </div>
};

export default BinLevelTile;
