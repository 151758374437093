import React, { useEffect, useState } from 'react';
import PieChart from '../../Charts/PieChart/index.js';
import "./styles.scss";
import { LabelWithLine } from '../../../../../components/index.js';
import { BIN_STATUS, calculateDuration, getCommonBinStatus } from '../../../../../utils/binHelper.jsx';

const SubJobCycle = ({ historyDetail = {} }) => {

    const [timeline, setTimeline] = useState([])

    useEffect(() => {
        const binStatus = getCommonBinStatus({ ...historyDetail?.bin, lastPickup: historyDetail })
        const eventTitles = binStatus == BIN_STATUS.BIN_MISSING ? {
            'pickedUp': "Loading",
            'transitToDropoff': "Travelling to drop-off"
        } : {
            'pickedUp': "Loading",
            'transitToDump': "Travelling to dump",
            'dumped': "Dumping",
            'transitToDropoff': "Travelling to drop-off"
        };
        let eventStatuses = binStatus == BIN_STATUS.BIN_MISSING ? ["pickedUp", "transitToDropoff"] :  ["pickedUp", "transitToDump", "dumped", "transitToDropoff"]

        let pieChartAttributes = eventStatuses.map(status => ({
            title: eventTitles[status],
            time: "00:00 h",
            duration: 0
        }));


        historyDetail?.events.forEach(event => {
            if (event.type in eventTitles) {
                const index = eventStatuses.indexOf(event.type);
                if (index !== -1) { // Ensure the event type is one of the statuses we're tracking
                    const time = event.endTime ? calculateDuration(event.startTime, event.endTime)?.time : "00:00 h";
                    const duration = event.endTime ? calculateDuration(event.startTime, event.endTime)?.duration : 0;
                    // Update the placeholder with actual data
                    pieChartAttributes[index] = { title: eventTitles[event.type], time, duration };
                }
            }
        });

        setTimeline(pieChartAttributes)
    }, [historyDetail])

    return (
        <>
            <LabelWithLine title={"Job Cycle Timeline"} />
        <div className='py-3'>
                <PieChart
                    data={{
                        "timeline": timeline,
                        binPickup: historyDetail
                    }}
                />
        </div>
       

        </>
    );
};

export default SubJobCycle;

