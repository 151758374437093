import React, { useContext, useEffect, useState } from "react";
import { SteelServLogo } from "../../assets/images/index";

import { newPasswordValidation } from "../../utils/binHelper";
import { API_METHODS, ENDPOINTS } from "../../utils/constants";
import { Spinner } from "react-bootstrap";
import { Button, InputField } from "../../components";

import "./styles.scss";
import { Navigate, useNavigate } from "react-router";
import { UserContext } from "../../services/userContext";

const SetPassword = () => {
  const navigate = useNavigate();
  const { ApiHandler } = useContext(UserContext);
  const searchParams = new URLSearchParams(document.location.search);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState({
    newPasswordErr: "",
    confirmPasswordErr: "",
  });
  const [loading, setLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const token = searchParams.get("key");
  const isInvited = searchParams.get("invited");
  useEffect(() => {
    ApiHandler({
      endPoint: ENDPOINTS.validateToken,
      method: API_METHODS.POST,
      reqParam: { token },
    })
      .then((res) => {
        setLoading(false);
        setIsValidToken(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [ApiHandler, token]);

  const handleType = (value, setterFunction) => {
    setterFunction(value);
    setErrorMsg({
      newPasswordErr: "",
      confirmPasswordErr: "",
    });
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    let errors = newPasswordValidation(newPassword, confirmPassword);
    const newpasswordErrorMsg = errors.password;
    const confirmpassErrorMsg = errors.confirmPassword;
    setErrorMsg({
      newPasswordErr: newpasswordErrorMsg,
      confirmPasswordErr: confirmpassErrorMsg,
    });
    if (newpasswordErrorMsg || confirmpassErrorMsg) {
      setLoading(false);
      return;
    }
    const data = {
      token,
      newPassword,
      confirmPassword,
    };
    try {
      await ApiHandler({
        reqParam: data,
        method: API_METHODS.POST,
        endPoint: ENDPOINTS.authChangePassword,
      });

      return navigate("/password-changed");
    } catch (err) {
      setIsValidToken(false);
      return navigate("/invalid-link", { state: { invited: isInvited } });
    }
  };
  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner />
      </div>
    );

  if (!isValidToken)
    return <Navigate to="/invalid-link" state={{ invited: isInvited }} />;
  return (
    <div className="d-flex bin_open-page-content  m-auto d-flex justify-content-between flex-column">
      <div className="bin_login-logo">
        <img src={SteelServLogo} className="bin_logo" alt="Eye" />
      </div>
      <div className="flex-1">
        <InputField
          label="Password"
          type={"password"}
          password
          value={newPassword}
          onChange={(value) => handleType(value, setNewPassword)}
          isError={errorMsg.newPasswordErr ? true : false}
          className=""
          style={{}}
          isDisabled={false}
        />
        <p className="bin_error-msg">{errorMsg.newPasswordErr}</p>
        <InputField
          label="Password"
          type={"password"}
          password
          value={confirmPassword}
          onChange={(value) => handleType(value, setConfirmPassword)}
          isError={errorMsg.confirmPasswordErr ? true : false}
          className=""
          style={{}}
          isDisabled={false}
        />
        <p className="bin_error-msg">{errorMsg.confirmPasswordErr}</p>
      </div>
      <div>
        <Button
          onClick={handleSubmitClick}
          className={""}
          loading={loading}
          isDisabled={errorMsg.confirmPasswordErr || errorMsg.newPasswordErr}
        >
          <div>Reset password</div>
        </Button>
      </div>
    </div>
  );
};

export default SetPassword;
