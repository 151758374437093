import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './styles.scss';

const DatePicker = ({ value, onChange, className, style }) => {
    const [date, setDate] = useState(value || moment().format('YYYY-MM-DD'));

    useEffect(() => {
        if (value) {
            setDate(value);
        }
    }, [value]);

    const handleDateChange = (e) => {
        const newDate = e.target.value ? moment(e.target.value).format('YYYY-MM-DD') : '';
        setDate(newDate);
        onChange(newDate);
    };

    const formatDate = (date) => {
        if(!date ) return "Select Date"
        return moment(date).isSame(moment(), 'day') ? 'Today' : moment(date).format('DD/MM/YYYY');
    };

    return (
        <div className={`bin_date-picker ${className}`} style={style}>
            <input
                type="date"
                value={date}
                onChange={handleDateChange}
                className={`bin_date-input ${moment(date).isSame(moment(), 'day') ? 'bin_today' : ''}`}
            />
            <div className="bin_selected-date-text">
                {formatDate(date)}
            </div>
        </div>
    );
};

export default DatePicker;
