export const getFirstLetter = (name) => {
    return name?.substring(0, 1).toUpperCase();
};

export const emailValidation = (email = '') => {
    const trimmed = email.trim();
    if (trimmed === '') {
        return 'Please enter your registered email address';
    } else if (
        !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ).test(trimmed)
    ) {
        return 'Please enter a valid email address';
    }
    return '';
};

export const passwordValidation = (pword) => {
    const trimmed = pword.trim();
    if (trimmed === '') {
        return 'Please enter password';
    }
    return '';
};

export const lengthValidation = (value, fieldName) => {
    const trimmed = value.trim();
    if (trimmed === '') {
        return `Please enter ${fieldName}`;
    } else if (trimmed.length < 3) {
        return `${getFirstLetter(fieldName).toUpperCase()}${fieldName.slice(
            1,
        )} must have at least 3 letters`;
    }
    return '';
};

export const newPasswordValidation = (pword, confirmPword) => {
    const trimmedPword = pword.trim();
    const trimmedConfirm = confirmPword.trim();
    const errors = { password: '', confirmPassword: '' };
    if (!trimmedPword) errors.password = 'Please enter password';
    else if (trimmedPword.length < 6) {
        errors.password = 'Password must be at least 6 characters';
    }

    if (!trimmedConfirm) errors.confirmPassword = 'Please enter confirm password';
    else if (trimmedPword && trimmedConfirm !== trimmedPword) {
        errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
};