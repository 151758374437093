import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Close } from '../../assets/images';
import { Button, Header, InputField } from '../../components';
import "./styles.scss";


import { UserContext } from '../../services/userContext';
import { API_METHODS, ENDPOINTS } from '../../utils/constants';
import { newPasswordValidation, passwordValidation } from '../../utils/binHelper';
import { toast } from 'react-toastify';

const EditPassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { ApiHandler } = useContext(UserContext);

    const [errorMsg, setErrorMsg] = useState({
        newpasswordErrorMsg: '',
        confirmpassErrorMsg: '',
        currentPassErrorMsg: '',
    });


    const navigate = useNavigate();

    const handleSubmitClick = async (e) => {
        e.preventDefault();

        let currentPassErrorMsg = passwordValidation(currentPassword) ?? '';
        const errors = newPasswordValidation(newPassword, confirmPassword) ?? '';
        const newpasswordErrorMsg = errors.password ?? '';
        const confirmpassErrorMsg = errors.confirmPassword ?? '';


        if (currentPassErrorMsg || newpasswordErrorMsg || confirmpassErrorMsg) {
            setErrorMsg({
                newpasswordErrorMsg,
                confirmpassErrorMsg,
                currentPassErrorMsg,
            })
            currentPassErrorMsg && toast.error(currentPassErrorMsg)
            newpasswordErrorMsg && toast.error(newpasswordErrorMsg)
            confirmpassErrorMsg && toast.error(confirmpassErrorMsg)
            return;
        }

        try {
            const data = {
                currentPassword: currentPassword,
                newPassword: newPassword.trim(),
                confirmPassword: confirmPassword.trim(),
            };
            const response = await ApiHandler({
                reqParam: data,
                method: API_METHODS.POST,
                endPoint: ENDPOINTS.changePassword,
            });

            if (response.status === 200) {
                toast.success("Password changed successfully")
                navigate('/profile');

            }

        } catch (error) {
            if (error?.status === 401) {
                setErrorMsg({
                    confirmpassErrorMsg: '',
                    newpasswordErrorMsg: '',
                    currentPassErrorMsg : "Wrong Credentials",
                })
                toast.error("The current password you have provided is incorrect")

            } else {
                toast.error(error?.message);
            }
        }
    };




    return (
        <div className='d-flex justify-content-between flex-column'>
            <Header
                label={"Edit Profile"}
                className='bin_header-border'
                right={<div onClick={() => { navigate('/profile'); }}> <Close /> </div>}
            />
            <div className='bin_page-content'>
                <div className='bin_change_password-container d-flex h-100 justify-content-between flex-column'>
                    <div className='d-flex h-100 flex-column'>
                        <div className='flex-1 mt-1'>
                            <div className='mt-1'>
                                <InputField
                                    label="Current Password"
                                    value={currentPassword}
                                    onChange={(value) => { setCurrentPassword(value) }}
                                    className=""
                                    style={{}}
                                    isError={errorMsg.currentPassErrorMsg ? true : false}

                                    type={"password"}
                                    password
                                />
                            </div>
                            <div className='mt-5'>
                                <InputField
                                    label="New Password"
                                    value={newPassword}
                                    onChange={(value) => { setNewPassword(value) }}
                                    isError={errorMsg.newpasswordErrorMsg ? true : false}
                                    className=""
                                    style={{}}
                                    type={"password"}
                                    password
                                />
                            </div>
                            <div className='mt-5'>
                                <InputField
                                    label="Confirm New Password"
                                    value={confirmPassword}
                                    onChange={(value) => { setConfirmPassword(value) }}
                                    className=""
                                    style={{}}
                                    type={"password"}
                                    isError={errorMsg.confirmpassErrorMsg ? true : false}
                                    password
                                />
                            </div>
                        </div>
                    </div>
                    <div className='edit-profile-footer'>
                        <Button
                            onClick={handleSubmitClick}
                            className={""}
                            // loading={isLoading}
                            isDisabled={!currentPassword?.length || !newPassword?.length || !confirmPassword?.length}
                        >
                            <div>Save Changes</div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPassword;
