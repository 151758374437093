import { createContext, FC, useState, ReactNode } from "react";
import io, { Socket } from "socket.io-client";
import { Bin } from "../utils/types";

// this type is used when receiving events
type ServerToClientEvents = {
  updatedBinList: (updateBinListData: Bin[]) => void;
};

// this type is used when sending events.
type ClientToServerEvents = {};

type ServerAndClientEvents = ServerToClientEvents & ClientToServerEvents;

export type CurrentSocketContext = {
  getSocket: () => Socket<ServerAndClientEvents>;
};

const socketConnection = (): Socket<ServerAndClientEvents> => {
  return io("/", { query: { room: "admin" } });
};

export const SocketContext = createContext<CurrentSocketContext>({
  getSocket: () => {
    throw new Error("You cannot access the socket outside the Socket Context");
  },
});

type SocketProviderProps = {
  children: ReactNode;
};
export const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket<ServerAndClientEvents> | null>(
    null,
  );

  const getSocket = (): Socket<ServerAndClientEvents> => {
    if (!socket) {
      const newSocket = socketConnection();
      setSocket(newSocket);
      return newSocket;
    } else return socket;
  };

  return (
    <SocketContext.Provider value={{ getSocket }}>
      {children}
    </SocketContext.Provider>
  );
};
