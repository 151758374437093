import { LOG_LEVEL, LogLevel } from "./constants";

export interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

export interface Logger {
  warn: LogFn;
  debug: LogFn;
  error: LogFn;
}

// a function used to disable logging.
const NO_OP: LogFn = () => { };

class ConsoleLogger implements Logger {
  readonly warn: LogFn;
  readonly debug: LogFn;
  readonly error: LogFn;

  constructor() {
    this.error = console.error.bind(console);
    // not shown in production.
    this.warn = LOG_LEVEL === LogLevel.ERROR ? NO_OP : console.warn.bind(console);

    // not shown in production
    this.debug = LOG_LEVEL === LogLevel.ERROR ? NO_OP : console.debug.bind(console);
  }
}

export const logger = new ConsoleLogger();