import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Close } from "../../../../../assets/images/index.js";
import {
  BottomModal,
  Button,
  Header,
  Modal,
} from "../../../../../components/index.js";
import BinTitle from "../../BinTitle/index.js";
import BinsCardLabel from "../../BinsCardLabel.js";
import SubBin from "./SubBin.js";
import SubJob from "./SubJob.js";
import SubJobCycle from "./SubJobCycle.js";
import SubJobTimeline from "./SubJobTimeline.js";
import "./styles.scss";

const BinHistoryDetail = ({ bin = {}, historyDetail = null, onClose }) => {
  const [showActionModal, setShowActionModal] = useState(false);

  if (!historyDetail) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={historyDetail != null}
        backdrop
        className=""
        style={{}}
        bodyClassName="p-0"
        onClose={onClose}
      >
        <Header
          left={historyDetail.pickupRef}
          className="bin_header-border"
          right={
            <div onClick={onClose}>
              <Close />
            </div>
          }
        />

        <div className="bin_history_detail-container">
          <div onClick={() => {}} className="bin_history_card-details">
            <div
              className={`d-flex bin_page_card-header  justify-content-between`}
            >
              <BinTitle
                bin={{ ...historyDetail?.bin, lastPickup: historyDetail }}
                displayHistoricalStatus
              />
              <div
                onClick={() => {
                  setShowActionModal(true);
                }}
                className="pl-2"
              >
                <BsThreeDotsVertical size={25} />
              </div>
            </div>

            <div className="py-4">
              <BinsCardLabel
                bin={{ ...historyDetail?.bin, lastPickup: historyDetail }}
                displayHistoricalStatus
              />
            </div>
          </div>

          <SubBin bin={bin} />

          <SubJob historyDetail={historyDetail} />

          <SubJobCycle historyDetail={historyDetail} />

          <SubJobTimeline historyDetail={historyDetail} />
        </div>

        <BottomModal
          isOpen={showActionModal}
          backdrop
          className="bin_customClass"
          style={{}}
          onClose={() => {
            setShowActionModal(false);
          }}
        >
          <div className="bin_dashboardActions">
            <div className="bin_dashboardTitle">
              {historyDetail?.pickupRef} - Actions
            </div>
            <div className="">
              <Button
                className="mb-2"
                onClick={() => {
                  /* handle PDF download */
                }}
              >
                Download PDF Report
              </Button>
              <Button
                className=""
                onClick={() => {
                  /* handle CSV download */
                }}
              >
                Download CSV Report
              </Button>
            </div>
          </div>
        </BottomModal>
      </Modal>
    </>
  );
};

export default BinHistoryDetail;
