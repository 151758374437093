
import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components';
import "./styles.scss";
import { modals } from '../../constants';
import { BUTTON_ACTION, getRequiredBinButtons } from '../../../../utils/binHelper';

const ActionMenu = ({ bin, setCurrentModal, setShowActionModal, handleAction }) => {
    const [visibleButtons, setVisibleButtons] = useState([]);

    useEffect(() => {
        const requiredButtons = getRequiredBinButtons(bin);
        setVisibleButtons(requiredButtons)
    }, [bin])

    const handleCommonActions = () => {
        setShowActionModal(false);
    }


    return <div className="bin_dashboardActions">
        <div className="bin_dashboardTitle">Bin {bin?.name} - Actions</div>
        <div className="">
            {visibleButtons.includes(BUTTON_ACTION.UPDATE_LEVEL) && <Button className="mb-1" onClick={() => { handleCommonActions(false); handleAction(bin, modals.updateLevel); }}>Update Level</Button>}
            {visibleButtons.includes(BUTTON_ACTION.TRACK_BIN) && <Button className="mb-1" onClick={() => { handleCommonActions(false); handleAction(bin, modals.trackBin); }}>Track Bin</Button>}
            {visibleButtons.includes(BUTTON_ACTION.REQUEST_PICKUP) && <Button className="mb-1" onClick={() => { handleCommonActions(false); handleAction(bin, modals.requestPickup); }}>Request Pickup</Button>}
            {visibleButtons.includes(BUTTON_ACTION.CANCEL_PICKUP) && <Button className="mb-1" onClick={() => { handleCommonActions(false); handleAction(bin, modals.cancelPickup); }}>Cancel Pickup</Button>}

            {visibleButtons.includes(BUTTON_ACTION.ADD_HAZARDS) && <Button className="mb-1 mt-5" onClick={() => { handleCommonActions(false); handleAction(bin, modals.addHazards); }}>Add Hazards</Button>}
            {visibleButtons.includes(BUTTON_ACTION.UPDATE_HAZARDS) && <Button className="mb-1 mt-5" onClick={() => { handleCommonActions(false); handleAction(bin, modals.updateHazards); }}>Update Hazards</Button>}
            {visibleButtons.includes(BUTTON_ACTION.BIN_HISTORY) && <Button className="mb-2" onClick={() => { handleCommonActions(false); handleAction(bin, modals.binHistory); }}>Bin History</Button>}
        </div>
    </div>
};

export default ActionMenu;
