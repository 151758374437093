import { Spinner } from 'react-bootstrap';
import "./styles.scss"

const LoadingScreen = () => {
    return (
        <div className="text-center py-4 custom-spinner">
            <Spinner animation="border " role="status" >
                <span className="visually-hidden"></span>
            </Spinner>
        </div>
    );
};

export default LoadingScreen;
