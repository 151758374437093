import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Header, InputField, Right, toast } from '../../components';
import "./styles.scss";
import { Close } from '../../assets/images';
import { UserContext } from '../../services/userContext';
import { lengthValidation } from '../../utils/binHelper';
import { API_METHODS, ENDPOINTS } from '../../utils/constants';

const EditProfile = () => {
    const { userInfo, ApiHandler, setUserInfo } = useContext(UserContext);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    const [errorMsg, setErrorMsg] = useState({
        firstErrorMsg: '',
        lastErrorMsg: '',
    });

    const handleFirstnameChange = (value) => {
        setFirstname(value);
    };
    const handleLastnameChange = (value) => {
        setLastname(value);
    };

    const navigate = useNavigate();


    const handleSubmitClick = async (e) => {
        e.preventDefault();

        const trimmedFirst = firstname.trim();
        const trimmedLast = lastname.trim();
        const firstErrorMsg = lengthValidation(trimmedFirst, 'first name');
        const lastErrorMsg = lengthValidation(trimmedLast, 'last name');

        setErrorMsg({ firstErrorMsg, lastErrorMsg });
        if (firstErrorMsg || lastErrorMsg) {
            return;
        }

        try {
            const data = {
                first_name: trimmedFirst,
                last_name: trimmedLast,
                user_id: userInfo._id,
                status: userInfo.status,
                user_type: userInfo.type,
            };
            const response = await ApiHandler({
                reqParam: data,
                method: API_METHODS.PUT,
                endPoint: ENDPOINTS.editUser,
            });

            setUserInfo({...userInfo ,
                first_name : firstname,
            last_name : lastname })
            if (response.status === 200) {
                setTimeout(() => {
                    navigate('/profile');
                }, 1000);
                toast.success('Details successfully updated');
            }
        } catch (error) {
            toast.error(error?.message);
        }
    };


    useEffect(() => {
        setFirstname(userInfo?.first_name)
        setLastname(userInfo?.last_name)
    }, [userInfo])

    return (
        <div className=' w-100  ' >
            <Header
                label={"Edit Profile"}
                className='bin_header-border'

                right={<div onClick={() => { navigate('/profile'); }}> <Close /> </div>}
            />
            <div className=' bin_page-content d-flex justify-content-between flex-column'>
                <div className=' edit-profile-body '>
                    <div className='d-flex flex-column'>
                        <div className=''>
                            <InputField
                                label="First Name"
                                labelFont="Username"
                                placeholder="Enter your First Name"
                                value={firstname}
                                onChange={handleFirstnameChange}
                                className=""
                                style={{}}
                                type="text"
                                isDisabled={false}
                            />
                        </div>
                        <div className=''>
                            <InputField
                                label="Last Name"
                                placeholder="Enter your Last Name"
                                value={lastname}
                                onChange={handleLastnameChange}
                                className=""
                                style={{}}
                                type={"text"}
                                isDisabled={false}
                            />
                        </div>
                    </div>
               
                    <div className='edit-profile-footer'>
                        <Button
                            onClick={handleSubmitClick}
                            className={""}
                            // loading={isLoading}
                            isDisabled={(!firstname?.length || !lastname?.length) || (userInfo?.first_name == firstname && userInfo?.last_name == lastname) }
                        >
                            <div>Save Changes</div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
