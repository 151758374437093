import React, { useEffect, useState } from 'react';
import { HidePassword, ShowPassword } from "../../../assets/images/index"
import './styles.scss';

const InputField = ({
    label,
    placeholder,
    value,
    onChange,
    className,
    style,
    type,
    onFocus,
    onBlur,
    isDisabled,
    password,
    isError
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleFocus = (e) => {
        setIsFocused(true);
        onFocus && onFocus(e);
    };

    const handleBlur = (e) => {
        setIsFocused(false);
        onBlur && onBlur(e);
    };

    return (
        <div className='bin_outside-field' >
            {label && <div className="bin_input-label">{label}</div>}
            <div className='d-flex w-100 position-relative '>
                <div className={`w-100 bin_input-field  ${className}`}>
                    <input
                        type={showPassword ? "text" : type || 'text'}
                        placeholder={placeholder ?? ''}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        className={`bin_inside-field ${className} ${isError ? " bin_inside-field-error " : ""}`}
                        style={{
                            ...style,
                        }}
                        autoCapitalize='none'
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={isDisabled}
                    />
                </div>
                {
                    password && (
                        <>
                            <button
                                className={`bin_eye-input`}
                                onClick={() => { setShowPassword(!showPassword) }}
                            >
                                <img
                                    src={showPassword ? ShowPassword : HidePassword}
                                    alt="Eye"
                                />
                            </button>
                        </>
                    )
                }
            </div>
        </div >
    );
};

export default InputField;
