export const API_METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
};

export const ZONE_DATA = {
    ZONE1: { color: '#138BAA', name: 'zone1', label: 'Zone 1' },
    ZONE2: { color: '#FAF265', name: 'zone2', label: 'Zone 2' },
    ZONE3: { color: '#4C4B66', name: 'zone3', label: 'Zone 3' },
    ZONE4: { color: '#FFC850', name: 'zone4', label: 'Zone 4' },
    ZONE5: { color: '#00C48C', name: 'zone5', label: 'Zone 5' },
    ZONE6: { color: '#6C63FF', name: 'zone6', label: 'Zone 6' },
};

export const ENDPOINTS = {
    login: 'auth/login',
    logout: 'users/end-shift',
    forgotPassword: 'auth/forgot-password',
    resetPassword: 'users/reset-password',
    getDepartments: 'departments/',
    editUser: 'users/edit-user',
    changePassword: "users/change-password",
    authChangePassword: 'auth/change-password',
    getLicenses: 'users/licenses',
    getNewToken: 'auth/refresh-token',
    getAllBins: '/bins',
    getMyBins: (email: string) =>  `/bins/supervisor/${email}`,
    updateBin : '/bins/bin',
    requestPickup : '/bins/pickup-request',
    summary: '/bins/summary',
    supervisorHistory: '/bins/history/supervisor',
    validateToken: 'auth/validate-token',
};

export const SOCKETS = {
    water: 'waterUsage',
    zones: 'zoneStatus',
    trucks: 'truckStatus',
};


export const UserType = {
    admin: { value: 'Admin', name: 'Super Admin' },
    supervisor: { value: 'Supervisor', name: 'Supervisor' },
    operator: { value: 'Operator', name: 'Operator' },
};

export const UserStatus = {
    invited: 'Invited',
    inactive: 'Inactive',
    active: 'Active',
};


export const userType = {
    Supervisor: 'Supervisor',
    Admin: 'Super Admin',
    Operator: 'Operator',
};


export const WebSocketURL: string =
  process.env.REACT_APP_ENV === 'staging'
    ? 'wss://ws.optitrac.com/steelserv-staging?application='
    : 'wss://ws.optitrac.com/steelserv-production?application=';
export enum SocketRoom {
  SCRAP_BINS = 'scrap_bins',
}
export enum SocketClient {
  ADMIN = 'admin',
}

export enum LogLevel {
  DEBUG = 'debug',
  ERROR = 'error',
  WARN = 'warn'
}

export enum Environments {
  Prod = 'production',
  Staging = 'staging',
}
export const LOG_LEVEL: LogLevel = process.env.REACT_APP_ENV === Environments.Prod ? LogLevel.ERROR : LogLevel.DEBUG;
