import { FC } from "react";
import { BinGreen } from "../../assets/images";
type DisplayBinWithTextProps = {
  text: string;
  binSize?: number
}
const DisplayBinWithText: FC<DisplayBinWithTextProps> = (props) => {
  return ( 
    <div className='d-flex align-items-center justify-content-center' style={{gap: '1rem'}}>
      <BinGreen binSize={props.binSize}/>
      <span className="fw-bold h1">{props.text}</span> 
    </div>
   );
}
 
export default DisplayBinWithText;