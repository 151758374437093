import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/scss/index.scss";
import { Layout } from "./components";
import {
  BinsDetail,
  Dashboard,
  EditPassword,
  EditProfile,
  Login,
  Notifications,
  Profile,
} from "./views";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinners from "./components/spinner";
import { ProtectRoute, RedirectToLandingPage } from "./services/protectRoute";
import ForgotPassword from "../src/views/Profile/ForgotPassword";
import ScreenWithMessage from "./views/Profile/ScreenWithMessage";
import SetPassword from "./views/Profile/SetPassword";

const RouteWithLayout = ({ children }) => <Layout>{children}</Layout>;

function App() {
  console.error("ENVIRONEMN", process.env.REACT_APP_ENV, process.env);
  return (
    <div>
      <Suspense fallback={<Spinners />}>
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route
            path="/password-changed"
            element={
              <ScreenWithMessage
                message="Password set successfully!"
                buttonText="Login"
                navigationLink="/login"
              />
            }
          />
          <Route
            path="/invalid-link"
            element={
              <ScreenWithMessage
                buttonText="Try again"
                message="Sorry, your reset password link is no longer valid. Try requesting a new link."
                navigationLink="/forgot-password"
              />
            }
          />

          <Route element={<RedirectToLandingPage />}>
            <Route path="/" element={<Login />} />
            {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
          </Route>

          <Route element={<ProtectRoute />}>
            <Route
              path="/dashboard"
              element={
                <RouteWithLayout>
                  <Dashboard />
                </RouteWithLayout>
              }
            />
            <Route
              path="/bins"
              element={
                <RouteWithLayout>
                  <BinsDetail />
                </RouteWithLayout>
              }
            />
            <Route
              path="/bins-detail"
              element={
                <RouteWithLayout>
                  <BinsDetail />
                </RouteWithLayout>
              }
            />
            <Route
              path="/notifications"
              element={
                <RouteWithLayout>
                  <Notifications />
                </RouteWithLayout>
              }
            />
            <Route
              path="/profile"
              element={
                <RouteWithLayout>
                  <Profile />
                </RouteWithLayout>
              }
            />
            <Route
              path="/edit-profile"
              element={
                <RouteWithLayout>
                  <EditProfile />
                </RouteWithLayout>
              }
            />
            <Route
              path="/edit-password"
              element={
                <RouteWithLayout>
                  <EditPassword />
                </RouteWithLayout>
              }
            />
            <Route
              path="*"
              element={
                <RouteWithLayout>
                  <Dashboard />
                </RouteWithLayout>
              }
            />
          </Route>
        </Routes>
      </Suspense>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
