export enum SocketEvent {
  UPDATED_BIN_LIST = "updatedBinList",
  PICKUP_CHANGE = "pickupStatusChange",
  NEW_PICKUP = "newPickupStarted",
}

export enum PickupEventType {
  TRANSIT_PICKUP = "transitToPickup",
  PICKUP = "pickedUp",
  TRANSIT_DUMP = "transitToDump",
  DUMP = "dumped",
  TRANSIT_DROPOFF = "transitToDropoff",
  DELIVER = "delivered",
}

export type BinPickup = {
  bin: Bin;
  pickupRef: string;
  status: PickupStatus;
  type: PickupType;
  truckName: string;
  operator?: Operator;
  endTime?: Date;
  isCurrentlyOnTruck?: boolean;
  events: PickupEvent[];
};

export type PickupEvent = {
  startTime: Date;
  endTime?: Date;
  type: PickupEventType;
} & Partial<Location>;

export enum PickupType {
  SCHEDULED = "scheduled",
  UNSCHEDULED = "unscheduled",
}

export type Operator = {
  _id: string;
  firstName: string;
  lastName: string;
};

export enum PickupStatus {
  COMPLETE = "complete",
  IN_PROGRESS = "inProgress",
  INCOMPLETE = "incomplete",
  CANCELLED = "cancelled",
}

export type Bin = {
  _id: string;
  name: string;
  pickupPoint: string;
  dumpPoint: string;
  dropoffPoint: string;
  scrapType: string;
  binLevel: number;
  hazards?: string;
  pickupRequest?: {
    time: Date;
    dropoffOnly?: boolean;
  };
};

export type BinWithPickup = Bin & {
  lastPickup?: BinPickup;
  lastEmptiedTime: string;
  lastUpdatedTime: string;
  lastLocation: Location;
};

type PickupChangeSocketResponse = {
  socketEvent: SocketEvent.PICKUP_CHANGE;
  type: PickupEventType;
  pickupReferenceNumber: string;
  location: string;
  startTime: Date;
  endTime?: Date;
  pickupDocument: BinPickup; // required for web
};

export type BinListUpdateSocketResponse = {
  socketEvent: SocketEvent.UPDATED_BIN_LIST;
  bins: BinWithPickup[];
};

type NewPickupSocketResponse = {
  socketEvent: SocketEvent.NEW_PICKUP;
  cancelledPickupRefNo?: string;
  newPickup: BinPickup;
};

export type SocketResponse =
  | PickupChangeSocketResponse
  | BinListUpdateSocketResponse
  | NewPickupSocketResponse;
