import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './styles.scss';

const MonthPicker = ({ value, onChange, className, style }) => {
    // Default to current date if no value is provided
    const [month, setMonth] = useState(value || moment().format('YYYY-MM'));

    useEffect(() => {
        // If the external value changes, update the month state
        if (value) {
            setMonth(value);
        }
    }, [value]);

    const handleMonthChange = (event) => {
        const newMonth = event.target.value;
        setMonth(newMonth);
        // Call the passed onChange function with the new date in 'YYYY-MM' format
        onChange(newMonth);
    };

    return (
        <div className={`bin_month-picker ${className}`} style={style}>
            <input
                type="month"
                value={month}
                onChange={handleMonthChange}
                className={`bin_month-input`}
            />
           
        </div>
    );
};

export default MonthPicker;
