import React from 'react';
import "./styles.scss";
import { getHistoryStatusLabels } from '../../../../../../utils/binHelper';
import moment from 'moment';
import { GoDotFill } from 'react-icons/go';

const HistoryTile = ({ history, setSelectedHistory }) => {
    return (
        <>
            <div className='bin_history_tile-container' onClick={setSelectedHistory}>
                <div className='bin_history_tile-title'>{history?.pickupRef}</div>
                <div className='d-flex justify-content-start align-items-center '>
                    <div className='bin_history_tile-desc'>{getHistoryStatusLabels(history?.status)}</div>
                    <GoDotFill size={8} className="mt-1 ml-3" color="#6A6A6A" />
                    <div className='bin_history_tile-desc bin_history_status-text'>  {history?.endTime ? moment(history?.endTime)?.format("HH:mm") : "00:00"} hrs</div>
                </div>
            </div>
        </>
    );
};

export default HistoryTile;
