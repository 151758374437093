import React from 'react';
import "./styles.scss";

const Button = ({
  onClick,
  className,
  style,
  loading,
  isDisabled,
  children,
}) => {

  return (

    <button
      onClick={onClick}
      style={{ ...style }}
      className={`bin_btn${isDisabled ? "-disabled" :  ""} ${className}`}
      disabled={isDisabled || loading}
    >
      {loading ? 'Loading...' : children}
    </button>

  );
};

export default Button;
