import { getStatusLabel, getStatusLabelColor } from "../../../utils/binHelper";

const BinsCardLabel = ({ bin = {}, displayHistoricalStatus }) => {
  return (
    <div
      className="bin_page-card-label"
      style={{
        backgroundColor: getStatusLabelColor(bin, displayHistoricalStatus),
      }}
    >
      {getStatusLabel(bin, displayHistoricalStatus)}
    </div>
  );
};

export default BinsCardLabel;
