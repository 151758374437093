import React from "react";
import KeyValueLabels from "./KeyValueLabels.js";
import "./styles.scss";
import { LabelWithLine } from "../../../../../components/index.js";
import moment from "moment";
import { getDurationAgo } from "../../../../../utils/binHelper.jsx";

const SubBin = ({ bin = {} }) => {
  return (
    <>
      <LabelWithLine title={"Bin Details"} />
      <KeyValueLabels
        value={(bin?.binLevel ?? "-") + " %"}
        title={"Bin Level:"}
      />
      <KeyValueLabels value={bin?.scrapType ?? "-"} title={"Scrap Type:"} />
      <KeyValueLabels value={bin?.pickupPoint ?? "-"} title={"Pickup:"} />
      <KeyValueLabels value={bin?.dumpPoint ?? "-"} title={"Dump:"} />
      <KeyValueLabels value={bin?.dropoffPoint ?? ""} title={"Drop-off:"} />
      <KeyValueLabels
        value={
          bin?.lastEmptiedTime
            ? moment(bin.lastEmptiedTime).format("DD/MM/YYYY, hh:mm A")
            : "-"
        }
        subValue={getDurationAgo(bin?.lastEmptiedTime)}
        title={"Last Emptied:"}
      />
      <KeyValueLabels value={bin?.hazards ?? "-"} title={"Hazards"} />
    </>
  );
};

export default SubBin;
