import * as React from "react"
const InfoDisabled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38.513}
    height={34.159}
    viewBox="0 0 38.513 34.159"
    {...props}
  >
    <g data-name="Group 46131">
      <g fill="#EbEbEb" data-name="Polygon 10">
        <path d="M33.504 33.16H5.008a3.957 3.957 0 0 1-3.473-2.016 3.957 3.957 0 0 1 .028-4.016L15.81 2.968a3.964 3.964 0 0 1 3.446-1.967c1.43 0 2.719.735 3.445 1.968l14.248 24.16a3.957 3.957 0 0 1 .028 4.015 3.957 3.957 0 0 1-3.473 2.016Z" />
        <path
          fill="#2b2d2f"
          d="M19.256 2a2.973 2.973 0 0 0-2.584 1.477L2.424 27.637c-1.18 2 .262 4.523 2.584 4.523h28.496c2.322 0 3.763-2.524 2.584-4.524L21.84 3.476a2.973 2.973 0 0 0-2.584-1.475m0-2c1.788 0 3.398.92 4.307 2.46L37.81 26.62a4.946 4.946 0 0 1 .034 5.02 4.946 4.946 0 0 1-4.341 2.52H5.008a4.946 4.946 0 0 1-4.342-2.52 4.946 4.946 0 0 1 .035-5.02L14.95 2.46A4.955 4.955 0 0 1 19.256 0Z"
        />
      </g>
      <path
        fill="#2b2d2f"
        d="M20.397 22.182H17.81l-.54-10.359h3.667Zm-3.17 3.626a1.778 1.778 0 0 1 .477-1.347 1.935 1.935 0 0 1 1.389-.456 1.88 1.88 0 0 1 1.362.467 1.773 1.773 0 0 1 .482 1.336 1.773 1.773 0 0 1-.488 1.32 1.846 1.846 0 0 1-1.357.482 1.9 1.9 0 0 1-1.378-.472 1.765 1.765 0 0 1-.488-1.33Z"
        data-name="Path 15008"
      />
    </g>
  </svg>
)
export default InfoDisabled
