//@ts-nocheck
import React, { useCallback, useContext, useEffect, useState } from "react";
import { BottomModal, Header, LoadingScreen, Modal } from "../../components";
import { UserContext } from "../../services/userContext";
import {
  ActionMenu,
  AddHazards,
  BinHistory,
  BinLevelSelector,
  BinsCard,
  BinsCardDetails,
  CancelPickup,
  RequestPickup,
} from "./components";
import { modals } from "./constants";
import { ENDPOINTS, SocketClient, SocketRoom } from "../../utils/constants";
import BinHistoryDetail from "./components/BinHistory/BinHistoryDetail";
import { useSocket } from "../../hooks/useSocket";
import { Bin, SocketEvent, SocketResponse } from "../../utils/types";
import { logger } from "../../utils/logger";
import {
  CurrentSocketContext,
  SocketContext,
} from "../../services/EC2SocketContext";

const Bins = () => {
  const [currentModal, setCurrentModal] = useState(null);
  const [previousModal, setPreviousModal] = useState(null);
  const [showActionModal, setShowActionModal] = useState(false);
  const [selectedBin, setSelectedBin] = useState<Bin>({});
  const [bins, setBins] = useState<Bin[]>([]);
  const [loading, setLoading] = useState(false);
  const { ApiHandler, userInfo } = useContext(UserContext);
  const { getSocket }: CurrentSocketContext = useContext(SocketContext);
  const socketHandler = useCallback(
    (socketData: SocketResponse) => {
      logger.debug("[Bins] component", { socketData, bins });
      if (socketData.socketEvent === SocketEvent.UPDATED_BIN_LIST) {
        let myBins = bins?.map((bin, index) => {
          return bin?.name;
        });
        /*
            This uses the data returned by the websocket to update the rendering of the 
            details shown on the bin cards whenever there are updates to the bin values 
          */
        setBins(socketData?.bins?.filter((bin) => myBins?.includes(bin?.name)));
        /*
            This uses the data returned by the websocket to update the rendering of the 
            details shown on the bin details component for a bin which is opened by clicking on the 
            bin card component whenever there are updates to the bin values 
          */
        if (selectedBin?.name) {
          setSelectedBin(
            socketData?.bins?.find((bin) => selectedBin.name === bin?.name),
          );
        }
      }
    },
    [bins, selectedBin.name],
  );

  useSocket<SocketResponse>(
    SocketRoom.SCRAP_BINS,
    socketHandler,
    SocketClient.ADMIN,
  );

  useEffect(() => {
    const socket = getSocket();
    socket?.on("updatedBinList", (data) => {
      console.log("updatedBinList socket emitted from ec2");
      /*
                  This uses the data returned by the websocket to update the rendering of the 
                  details shown on the bin cards whenever there are updates to the bin values 
                */
      setBins((previousBins) => {
        const myBins = previousBins.map((bin) => bin.name);
        return data?.filter((bin) => myBins.includes(bin.name));
      });
      /*
                  This uses the data returned by the websocket to update the rendering of the 
                  details shown on the bin details component for a bin which is opened by clicking on the 
                  bin card component whenever there are updates to the bin values 
                */
      if (selectedBin?.name) {
        setSelectedBin(data?.find((bin) => selectedBin.name === bin?.name));
      }
    });
    return () => {
      socket?.off("updatedBinList");
    };
  }, []);

  const fetchBins = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ApiHandler({
        method: "GET",
        endPoint: `${ENDPOINTS.getMyBins(userInfo.email)}`,
      });
      setBins(response.data.bins);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [ApiHandler, userInfo.email]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      // state = visible | hidden
      const state = document.visibilityState;
      if (state === "visible") {
        fetchBins();
      }
    };

    // visibilitychange listens to the screen state, whether it's visible or hidden(in the background).
    // ref: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState.
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [fetchBins]);

  useEffect(() => {
    fetchBins();
  }, []);

  const handleAction = (bin, modalType) => {
    setSelectedBin(bin);
    setPreviousModal(currentModal);
    setCurrentModal(modalType);
  };

  const renderModal = ({
    closable,
    bodyClassName = "",
    isOpen,
    Component,
    extraProps = {},
    className,
    title,
    onClose = () => {},
  }) => {
    return (
      <Modal
        isOpen={isOpen}
        backdrop
        className={className}
        title={title}
        bodyClassName={bodyClassName}
        style={{}}
        closable={closable}
        onClose={() => {
          extraProps.onClose();
        }}
      >
        <Component
          onSave={() => {
            onClose();
            fetchBins();
            setCurrentModal(null);
          }}
          bin={selectedBin}
          {...extraProps}
        />
      </Modal>
    );
  };

  return (
    <>
      <div className=" d-flex justify-content-between flex-column">
        <Header label={"Bins"} />

        <div className="bin_page-card-body">
          {loading && <LoadingScreen />}

          {bins?.map((bin, index) => {
            return (
              <div key={index}>
                <BinsCard
                  bin={bin}
                  onUpdateLevel={() => handleAction(bin, modals.updateLevel)}
                  onTrackBin={() => {
                    handleAction(bin, modals.trackBin);
                  }}
                  onRequestPickup={() =>
                    handleAction(bin, modals.requestPickup)
                  }
                  onCancelPickup={() => handleAction(bin, modals.cancelPickup)}
                  onDetailClick={() => handleAction(bin, modals.binDetail)}
                  onRequestBinHistory={() =>
                    handleAction(bin, modals.binHistory)
                  }
                  onActionMenuClick={() => {
                    setShowActionModal(true);
                    setSelectedBin(bin);
                  }}
                  onChangeSocketEvent={(bin) => {
                    if (selectedBin?.name == bin?.name) {
                      setSelectedBin(bin);
                    }
                    setBins((previousBins) =>
                      previousBins.map((binInList) => {
                        if (binInList.name === bin.name) return bin;
                        else return binInList;
                      }),
                    );
                  }}
                />
              </div>
            );
          })}

          {!loading && !bins?.length && <div>No Bins Found</div>}
        </div>

        {renderModal({
          isOpen: currentModal === modals.updateLevel,
          Component: BinLevelSelector,
          className: "",
          extraProps: {
            onClose: () => {
              handleAction(selectedBin, previousModal);
            },
          },
          title: `Update Level - Bin ${selectedBin.name}`,
          closable: true,
        })}

        {renderModal({
          isOpen: currentModal === modals.binDetail,
          Component: BinsCardDetails,
          extraProps: {
            currentZoneBins: bins,
            onUpdateLevel: () => {
              handleAction(selectedBin, modals.updateLevel);
            },
            onTrackBin: () => {
              handleAction(selectedBin, modals.trackBin);
            },
            onRequestBinHistory: () => {
              handleAction(selectedBin, modals.binHistory);
            },
            onRequestPickup: () => {
              handleAction(selectedBin, modals.requestPickup);
            },
            onCancelPickup: () => {
              handleAction(selectedBin, modals.cancelPickup);
            },
            onActionMenuClick: () => {
              setShowActionModal(true);
            },
            onClose: () => {
              setCurrentModal(null);
            },
            toggleBin: (bin) => {
              setSelectedBin(bin);
            },
          },
          className: "",
          bodyClassName: "p-0",
          closable: false,
        })}

        {renderModal({
          isOpen: currentModal === modals.binHistory,
          Component: BinHistory,
          extraProps: {
            onClose: () => {
              handleAction(selectedBin, previousModal);
            },
          },
          className: "",
          bodyClassName: "p-0",
          closable: false,
        })}

        {currentModal === modals.trackBin && (
          <BinHistoryDetail
            bin={selectedBin}
            historyDetail={selectedBin?.lastPickup}
            onClose={() => handleAction(selectedBin, null)}
          />
        )}

        {renderModal({
          isOpen: currentModal === modals.requestPickup,
          Component: RequestPickup,
          extraProps: {
            onClose: () => {
              handleAction(selectedBin, previousModal);
            },
          },
          className: "p-5",
          bodyClassName: "",
          closable: true,
        })}

        {renderModal({
          isOpen: currentModal === modals.cancelPickup,
          Component: CancelPickup,
          extraProps: {
            onClose: () => {
              handleAction(selectedBin, previousModal);
            },
          },
          className: "p-5",
          bodyClassName: "",
          closable: true,
        })}

        {renderModal({
          isOpen:
            currentModal == modals.addHazards ||
            currentModal == modals.updateHazards,
          Component: AddHazards,
          extraProps: {
            hazard:
              currentModal == modals.updateHazards ? selectedBin?.hazards : "",
            type: currentModal == modals.addHazards ? "Add" : "Update",
            onClose: () => {
              handleAction(selectedBin, previousModal);
            },
          },
          className: "",
          bodyClassName: "",
          closable: true,
          title: `${
            currentModal == modals.addHazards ? "Add" : "Update"
          } Hazards`,
        })}

        {/*  Bin action modal */}
        <BottomModal
          isOpen={showActionModal}
          backdrop
          className=""
          style={{}}
          onClose={() => {
            setShowActionModal(false);
          }}
        >
          <ActionMenu
            bin={selectedBin}
            setCurrentModal={setCurrentModal}
            setShowActionModal={setShowActionModal}
            handleAction={handleAction}
          />
        </BottomModal>
      </div>
    </>
  );
};

export default Bins;
