import React from 'react';
import "./styles.scss";

const KeyValueLabels = ({ title, value, subValue }) => {
    return (
        <>
            <div className="row d-flex w-100">

                <div className='bin_key_value-title'>{title}</div>


                <div className='bin_key_value-answer d-flex flex-column'>
                    <div  className='d-flex flex-wrap'> {value}</div>
                    <div> {subValue}</div>

                </div>

            </div>
        </>
    );
};

export default KeyValueLabels;

