import * as React from "react";

const PickupAlert = (props) => (
  <svg
    id="Group_57560"
    data-name="Group 57560"
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 30 30"
    {...props}
  >
    <g
      id="Ellipse_3343"
      data-name="Ellipse 3343"
      fill="#ffe838"
      stroke="#2b2d2f"
      strokeWidth={2}
    >
      <circle cx={15} cy={15} r={15} stroke="none" />
      <circle cx={15} cy={15} r={14} fill="none" />
    </g>
    <path
      id="Path_34671"
      data-name="Path 34671"
      d="M5.733-26.294H3.386l-.491-9.4H6.224ZM2.856-23a1.613,1.613,0,0,1,.433-1.222,1.756,1.756,0,0,1,1.26-.414,1.706,1.706,0,0,1,1.236.423A1.609,1.609,0,0,1,6.224-23a1.608,1.608,0,0,1-.443,1.2,1.675,1.675,0,0,1-1.231.438A1.725,1.725,0,0,1,3.3-21.8,1.6,1.6,0,0,1,2.856-23Z"
      transform="translate(10.46 43.531)"
      fill="#2b2d2f"
    />
  </svg>
);
export default PickupAlert;
