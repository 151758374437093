import React, { useContext, useState } from 'react';
import "./styles.scss";
import BinLevelTile from './BinLevelTile';
import { Button, toast } from '../../../../components';
import { UserContext } from '../../../../services/userContext';
import { API_METHODS, ENDPOINTS } from '../../../../utils/constants';

const binLevels = [100, 75, 50, 25, 0]
const BinLevelSelector = ({ bin, onSave = () => { } }) => {
    const { ApiHandler } = useContext(UserContext);
    const [selectedLevel, setSelectedLevel] = useState(null);

    const updateLevel = async () => {
        try {
            const response = await ApiHandler({
                reqParam: {
                    level: selectedLevel,
                    binName: bin.name
                },
                method: API_METHODS.PUT,
                endPoint: ENDPOINTS.updateBin,
            });
            if (response.status === 200) {
                onSave();
                toast.success('Details successfully updated');
            }
        } catch (error) {
        }
    }

    return <div style={{ height: "100%" }} className='py-4 d-flex flex-column justify-content-between' >
        <div className=''>
            {
                binLevels?.map((level, index) => {
                    return <BinLevelTile level={level} isSelected={selectedLevel == level} onClick={() => { setSelectedLevel(level) }} />

                })
            }
        </div>

        <div className='mb-4'>
            <Button onClick={() => { updateLevel() }}>
                Update Level
            </Button>
        </div>

    </div>
};

export default BinLevelSelector;
