import { SteelServLogo } from "../../assets/images/index";
import React from "react";
import { useNavigate } from "react-router";
import { Button } from "../../components";

const ScreenWithMessage = ({ message, buttonText, navigationLink }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(navigationLink);
  };

  return (
    <div className="login-view">
      <div className="d-flex bin_open-page-content  m-auto d-flex justify-content-center flex-column">
        <div className="bin_login-logo">
          <img src={SteelServLogo} className="bin_logo" alt="Eye" />
        </div>

        <div className="flex-1">
          <div className="resetPassMessageWrapper">
            <h4 className="text-dark fw-bold resetPassMessage">{message}</h4>
          </div>
        </div>

        <Button onClick={onClick} className={""}>
          <div>{buttonText}</div>
        </Button>
      </div>
    </div>
  );
};

export default ScreenWithMessage;
