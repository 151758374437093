import React from 'react';
import "./styles.scss";

const ToggleButton = ({
    onClick,
    className,
    style,
    isActive,
    children
}) => {

    return (

        <button
            onClick={onClick}
            className={`bin_btn-toggle${isActive ? "-active" : ""} ${className}`}
            style={{ ...style }}
        >
            <div style={{ fontSize: "14px", lineHeight : "14px", fontWeight : 700 }}>{children}</div> 
        </button>

    );
};

export default ToggleButton;
