import { useContext, useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LocationIcon, MissingAlert } from "../../assets/images";
import {
  Button,
  DatePicker,
  Header,
  LoadingScreen,
  MonthPicker,
  toast,
} from "../../components";
import { BottomModal } from "../../components/Modals";
import { UserContext } from "../../services/userContext";
import { formatWeeklyData, getDurationAgo, getTotalLoads } from "../../utils/binHelper";
import { API_METHODS, ENDPOINTS } from "../../utils/constants";
import { PieChart, ToggleButton, WeeklyProgressBars } from "./components";
import MonthlyProgressBars from "./components/Charts/MonthlyProgressBars";
import "./styles.scss";
import moment from "moment";

const Dashboard = () => {
  const [showActionModal, setShowActionModal] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [month, setMonth] = useState(moment().format("YYYY-MM"));
  const [isMonthActive, setMonthActive] = useState(false);
  const { ApiHandler } = useContext(UserContext);
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(0);

  const fetchDashboardDetails = async () => {
    setLoading(true);
    try {
      const response = await ApiHandler({
        method: API_METHODS.GET,
        endPoint: `${ENDPOINTS.summary}${
          isMonthActive ? "?month=" + month : "?day=" + date
        }`,
      });
      if (response.status === 200) {
        setDashboardData(response.data);
      }
    } catch (error) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDashboardDetails();
  }, [date, month, isMonthActive]);


  return (
    <div className=" d-flex justify-content-between flex-column p-0 ">
      <Header
        label={"Dashboard"}
        right={
          <div
            onClick={() => {
              setShowActionModal(true);
            }}
          >
            <BsThreeDotsVertical />{" "}
          </div>
        }
      />

      {isMonthActive ? (
        <MonthPicker
          value={month}
          onChange={(v) => setMonth(v)}
          className="mx-4"
          style={{ marginBottom: "10px" }}
        />
      ) : (
        <DatePicker
          value={date}
          onChange={(v) => setDate(v)}
          className="mx-4"
          style={{ marginBottom: "10px" }}
        />
      )}

      <div className="bin_page-content-l-header  p-0">
        {loading ? (
          <div className="pt-5">
            <LoadingScreen />{" "}
          </div>
        ) : (
          <div className="d-flex h-100 flex-column justify-content-between">
            <div className="d-flex h-100 flex-column">
              <div className="bin_pie-chart-container mt-0">
                <div className="d-flex justify-content-center">
                  <ToggleButton
                    onClick={() => {
                      setMonthActive(false);
                    }}
                    isActive={!isMonthActive}
                    style={{ marginRight: "30px" }}
                  >
                    Day Totals
                  </ToggleButton>
                  <ToggleButton
                    onClick={() => {
                      setMonthActive(true);
                    }}
                    isActive={isMonthActive}
                  >
                    Month Totals
                  </ToggleButton>
                </div>
                <div className="my-5">
                  {isMonthActive ? (
                    <div className="mb-2">
                      {" "}
                      <em className="bin_month-title">
                        {moment(month)?.format("MMMM")}
                      </em>{" "}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="bin_dashboard-title">
                    Total Bin Loads:
                    <span className="bin_dashboard-bold-title">
                      {" "}
                      {getTotalLoads(dashboardData, isMonthActive, date).totalLoadCount} (LDS){" "}
                    </span>
                  </div>
                </div>
                <PieChart
                  legend
                  data={{loadSummary: getTotalLoads(dashboardData, isMonthActive, date).binLoads}}
                />

                <div className="d-flex bin_missing-alert-container align-items-start">
                  {dashboardData?.missing?.length ? (
                    <MissingAlert
                      width={24}
                      height={24}
                      className="bin_missing-alert-icon"
                    />
                  ) : (
                    <LocationIcon
                      width={24}
                      height={24}
                      className="bin_missing-alert-icon"
                    />
                  )}

                  <div className="d-flex flex-column">
                    <div className="bin_legend-pie-text">Missing Bin(s):</div>

                    {dashboardData?.missing?.length
                      ? dashboardData?.missing?.map((missingBin, index) => {
                          return (
                            <div
                              key={index}
                              className="bin_legend-pie-bold-text"
                            >
                              Bin {missingBin?.binName}{" "}
                              {getDurationAgo(missingBin?.lastSeenTime)}{" "}
                            </div>
                          );
                        })
                      : "-"}
                  </div>
                </div>
              </div>

              {isMonthActive
                ? formatWeeklyData(month, "month", dashboardData?.loads)?.map(
                    (monthData, index) => {
                      return (
                        <MonthlyProgressBars index={index} data={monthData} />
                      );
                    },
                  )
                : formatWeeklyData(date, "day", dashboardData?.loads)?.map(
                    (weekData, index) => {
                      return (
                        <WeeklyProgressBars
                          index={index}
                          currentDate={date}
                          data={weekData}
                        />
                      );
                    },
                  )}
            </div>
            <div className="d-flex flex-column justify-content-between">
              <div className="w-100"></div>
            </div>
          </div>
        )}
      </div>

      <BottomModal
        isOpen={showActionModal}
        backdrop
        className="bin_customClass"
        style={{}}
        onClose={() => {
          setShowActionModal(false);
        }}
      >
        <div className="bin_dashboardActions">
          <div className="bin_dashboardTitle">Dashboard - Actions</div>
          <div className="">
            <Button
              className="mb-2"
              onClick={() => {
                /* handle PDF download */
              }}
            >
              Download PDF Report
            </Button>
            <Button
              className=""
              onClick={() => {
                /* handle CSV download */
              }}
            >
              Download CSV Report
            </Button>
          </div>
        </div>
      </BottomModal>
    </div>
  );
};

export default Dashboard;
