import React from 'react';
import "./styles.scss";

const Checkbox = ({ label, checked, onChange, className, style, isDisabled }) => {
    return (
        <label className={`bin_checkbox-input ${className}`}>
            < input
                className='bin_checkbox'
                type="checkbox"
                checked={checked}
                onChange={onChange}
                disabled={isDisabled}
            />
            <label onClick={onChange}  for="checkbox" class="bin_checkbox_label"></label>
            {label && <span className='bin_label'>{label}</span>}
        </label >
    );
};

export default Checkbox;
