import React from 'react';
import './styles.scss'; // Ensure the SCSS file is named bin_BottomModal.scss and located in the same directory

const BottomModal = ({ isOpen, onClose, backdrop, className, style, children }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className={`bin_backdrop ${backdrop ? 'bin_backdrop--active' : ''}`} onClick={onClose}>
            <div
                className={`bin_bottomModal ${className}`}
                style={style}
                onClick={e => e.stopPropagation()} // Prevent click from closing modal
            >
                <div className='bin_bottomModal-bar'> </div>
                {children}
            </div>
        </div>
    );
};

export default BottomModal;
