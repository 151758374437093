import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENDPOINTS, API_METHODS } from '../utils/constants';
import { setAccessToken } from './accessToken';
import { API, apiHeaders } from './apiHandler';


export const AuthContext = React.createContext();
export const AuthContextProvider = (props) => {
    const [isloggedIn, setIsLoggedIn] = useState(false);
    const isLoggedOut = useRef(false);
    const navigate = useNavigate();

    const handleApiCall = async (config) => {
        const contentType = config.data instanceof FormData ? 'multipart/form-data' : 'application/json';
        config.headers = apiHeaders(config.data, contentType, 'application/json');
        try {
            const response = await API(config);
            let res = {};
            if (response.headers['content-type'] === 'application/pdf') {
                res = {
                    data: response.data,
                    status: response?.status,
                    statusText: response?.statusText,
                };
            } else {
                res = {
                    data: response.data.data,
                    status: response?.status,
                    statusText: response?.statusText,
                };
            }
            return res;
        } catch (e) {
            const error = e.response;
            let res = {};
            if (error) {
                res = {
                    data: error?.data?.data,
                    message: error?.data?.details ?? error?.data?.message,
                    status: error?.status,
                    statusText: error?.statusText,
                };
                if (error.status === 403) return navigate('/');
                else if (error.status === 500) res.message = 'Something went wrong';
            }
            return Promise.reject(res);
        }
    };

    const login = async (data) => {
        const response = await handleApiCall({
            data,
            method: API_METHODS.POST,
            url: ENDPOINTS.login,
            withCredentials: true,
            responseType: 'json',
        });
        if (response.status === 200) {
            setIsLoggedIn(true);
        }
        return response;
    };

    const forgotPassword = async (data) => {
        const response = await handleApiCall({
            url: ENDPOINTS.forgotPassword,
            data,
            method: API_METHODS.POST,
            responseType: 'json',
        });
        return response;
    };

    const signUp = async (data) => {
        const response = await handleApiCall({
            data,
            method: API_METHODS.POST,
            url: ENDPOINTS.resetPassword,
            responseType: 'json',
        });
        if (response.status === 200) {
            navigate('/');
        }
        return response;
    };

    const logOut = async () => {
        const response = await handleApiCall({
            method: API_METHODS.POST,
            url: ENDPOINTS.logout,
            responseType: 'json',
            withCredentials: true,
        });
        if (response.status === 200) {
            setIsLoggedIn(false);
            setAccessToken('');
            localStorage.setItem('accessToken', '');
            isLoggedOut.current = true;
            navigate('/');
        }
        return response;
    };

    return (
        <AuthContext.Provider
            value={{
                login,
                logOut,
                isloggedIn,
                forgotPassword,
                signUp,
                isLoggedOut: isLoggedOut.current,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};
