import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Bin,
    Dasboard,
    Notifications,
    Profile
} from '../../assets/images/index';
import "./styles.scss";

const BottomNavigation = () => {
    const location = useLocation();

    const renderLink = (to, inactiveIcon, altText) => (
        <Link className="navbar-brand" to={to}>
            <img
                className={`bin_navigation-icon ${location.pathname.includes(to) ? " bin_navigation-icon-active " : " "}`}
                src={ inactiveIcon}
                alt={altText}
            />
        </Link>
    );

    return (
        <nav className="navbar bin_bottom-navbar navbar-light bg-light px-5 py-4 pb-5">
            {renderLink("/dashboard", Dasboard, "Dashboard")}
            {renderLink("/bins", Bin, "Bins")}
            {renderLink("/notifications", Notifications, "Notifications")}
            {renderLink("/profile", Profile, "Profile")}
        </nav>
    );
};

export default BottomNavigation;
