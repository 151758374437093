import { RiRadioButtonLine } from "react-icons/ri";
import {
  BIN_STATUS,
  formatDate,
  getCommonBinStatus,
} from "../../../../../utils/binHelper";
import { useEffect, useState } from "react";
import moment from "moment";

const DOT_HEIGHT = 25;
const MIN_LINE_HEIGHT = 52;

const isDropOffOnly = (events = []) => {
  const isDumped = events?.find((event) => event?.type == "dumped");
  const istransitToDropoff = events?.find(
    (event) => event?.type == "transitToDropoff",
  );

  if (!isDumped && istransitToDropoff) {
    return true;
  }
  return false;
};

export const Dot = ({ Dotstyles, status }) => {
  const getColor = () => {
    if (status == "completed") return "#1b499f";
    if (status == "upcoming") return "#939292";
    if (status == "disconnected") return "#D54A4A";
    if (status == "current") return "#2A7B39";
    return "#939292";
  };
  const getBgColor = () => {
    if (status == "completed") return "#72A2FD";
    if (status == "upcoming") return "#E2E2E2";
    if (status == "disconnected") return "#FC8A8A";
    if (status == "current") return "#63E07A";
    return "#E2E2E2";
  };
  return (
    <RiRadioButtonLine
      color={getColor()}
      min={DOT_HEIGHT}
      size={DOT_HEIGHT}
      style={{
        backgroundColor: getBgColor(),
        borderRadius: "50%",
        // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        // padding: '0',
        margin: "0 auto",
        padding: "0px",
        ...(Dotstyles ? Dotstyles : {}),
      }}
    />
  );
};

export const DotDescription = ({ label, description, detail }) => (
  <hgroup style={{ fontSize: "16px", height: DOT_HEIGHT }}>
    <div
      className="mt-0"
      style={{
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: "600",
        color: "#000",
        marginBottom: "4px",
      }}
    >
      {label}
    </div>
    <div
      className="mt-0"
      style={{
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: "600",
        color: "#000",
        marginBottom: "4px",
      }}
    >
      {description}
    </div>
    <p
      style={{
        color: "#6A6A6A",
        fontSize: "16px",
        fontWeight: "600",
        height: "max-content",
        marginBottom: 0,
      }}
    >
      <em>{detail}</em>
    </p>
  </hgroup>
);

const Line = ({ active = false, height }) => {
  const color = active ? "#1b499f" : "#939292";
  const width = active ? "5px" : "3px";
  return (
    <div
      style={{
        backgroundColor: color,
        height: height,
        minHeight: height,
        margin: "0 auto",
        width: width,
      }}
    />
  );
};

export const HistoryTimeline = ({ historyDetail }) => {
  const [timelineAttributes, setTimelineAttributes] = useState([]);

  const getStatus = ({
    event,
    nextEventType,
    eventTime,
    nextEventTimeType,
    currentMarked,
    currentEventIndex,
  }) => {
    if (event?.type == "delivered" && event?.endTime) return "completed";
    if (currentEventIndex == 0 && !eventTime) return "current";

    const nextEvent = historyDetail.events.find(
      (e) => e.type === nextEventType,
    );
    if (!nextEvent?.[nextEventTimeType] && !currentMarked) return "current";

    if (eventTime) return "completed";
    if (!currentMarked && !eventTime) return "disconnected";
    return "upcoming";
  };

  const TIMELINE_STATUS =
    getCommonBinStatus({ ...historyDetail?.bin, lastPickup: historyDetail }) ==
      BIN_STATUS.BIN_MISSING || isDropOffOnly(historyDetail?.events)
      ? {
          ARRIVED_AT_PICKUP: "Arrived at pickup point",
          LOADED: "Loaded",
          TRAVEL_TO_DROP_OFF: "Travelling to drop-off point",
          ARRIVED_AT_DROP_OFF: "Arrived at drop-off point",
          BIN_DELIVERED: "Bin delivered",
        }
      : {
          ARRIVED_AT_PICKUP: "Arrived at pickup point",
          LOADED: "Loaded",
          TRAVEL_TO_DUMP: "Travelling to dump point",
          ARRIVED_AT_DUMP: "Arrived at dump point",
          SCRAP_DUMPED: "Scrap dumped",
          TRAVEL_TO_DROP_OFF: "Travelling to drop-off point",
          ARRIVED_AT_DROP_OFF: "Arrived at drop-off point",
          BIN_DELIVERED: "Bin delivered",
        };

  useEffect(() => {
    let currentMarked = false;
    const timelineEvents = Object.entries(TIMELINE_STATUS).map(
      ([timelineKey, timelineText], index, array) => {
        let eventType;
        let eventTime, nextEventType, nextEventTimeType;
        switch (timelineKey) {
          case "ARRIVED_AT_PICKUP":
            eventType = "transitToPickup";
            eventTime = "endTime";
            nextEventType = "pickedUp";
            nextEventTimeType = "endTime";
            break;
          case "LOADED":
            eventType = "pickedUp";
            eventTime = "endTime";
            nextEventType = isDropOffOnly(historyDetail?.events)
              ? "transitToDropoff"
              : "transitToDump";
            nextEventTimeType = "startTime";
            break;
          case "TRAVEL_TO_DUMP":
            eventType = "transitToDump";
            eventTime = "startTime";
            nextEventType = "transitToDump";
            nextEventTimeType = "endTime";
            break;
          case "ARRIVED_AT_DUMP":
            eventType = "transitToDump";
            eventTime = "endTime";
            nextEventType = "dumped";
            nextEventTimeType = "endTime";
            break;
          case "SCRAP_DUMPED":
            eventType = "dumped";
            eventTime = "endTime";
            nextEventType = "transitToDropoff";
            nextEventTimeType = "startTime";
            break;
          case "TRAVEL_TO_DROP_OFF":
            eventType = "transitToDropoff";
            eventTime = "startTime";
            nextEventType = "transitToDropoff";
            nextEventTimeType = "endTime";
            break;
          case "ARRIVED_AT_DROP_OFF":
            eventType = "transitToDropoff";
            eventTime = "endTime";
            nextEventType = "delivered";
            nextEventTimeType = "endTime";
            break;
          case "BIN_DELIVERED":
            eventType = "delivered";
            eventTime = "endTime";
            nextEventType = null;
            nextEventTimeType = null;
            break;
          default:
            eventType = null;
            eventTime = "endTime";
        }

        let event = historyDetail.events.find(
          (event) => event.type === eventType,
        );
        const endTime = event?.[eventTime] ?? null;

        // status will be "current" if there is an entry for next timelineKey inside historyDetail.events, and there exists value for eventTime key inside  that  historyDetail.events,
        const status = getStatus({
          event: event,
          nextEventType: nextEventType,
          eventTime: endTime,
          nextEventTimeType,
          currentMarked,
          currentEventIndex: index,
        });

        if (status == "current") {
          currentMarked = true;
        }

        const zone = event?.zone ?? "N/A";

        return {
          text: timelineText,
          description: "(" + zone + ")",
          endTime,
          status,
        };
      },
    );

    // Assuming you want to do something with timelineEvents like setting state or logging.
    setTimelineAttributes(timelineEvents); // For demonstration, log the results. Replace or remove as needed.
  }, [historyDetail]);

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "row",
        minHeight: "max-content",
      }}
    >
      <section
        style={{
          width: 30,
          marginRight: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {timelineAttributes?.map((timeline, index) => {
          return (
            <div>
              {index !== 0 && (
                <Line
                  active={timeline?.status != "upcoming"}
                  height={Math.max(MIN_LINE_HEIGHT, 65)}
                />
              )}
              <Dot status={timeline?.status} />
            </div>
          );
        })}
      </section>

      <section
        style={{
          display: "flex",
          flexDirection: "column",
          gap: Math.max(MIN_LINE_HEIGHT, 65),
          width: "fit-content",
        }}
      >
        {timelineAttributes?.map((timeline, index) => {
          return (
            <div>
              <DotDescription
                label={timeline?.text}
                description={timeline?.description}
                detail={
                  timeline?.endTime
                    ? moment(timeline?.endTime)?.format("hh:mm A")
                    : "-"
                }
              />
            </div>
          );
        })}
      </section>
    </section>
  );
};
