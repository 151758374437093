export const modals = {
    updateLevel: "updateLevel",
    requestPickup: "requestPickup",
    cancelPickup: "cancelPickup",
    addHazards: "addHazards",
    trackBin: "trackBin",
    updateHazards: "updateHazards",
    binDetail: "binDetail",
    binHistory: "binHistory",
    binHistoryDetail: "binHistoryDetail",
}

// https://activetrac.atlassian.net/wiki/spaces/STEEL/pages/520585308/Mobile+App+Bin+Statuses#Status:-Back-At-Source

export const bins = [
    {
        "_id": "659dca0f1079bb10c740af2e",
        "name": "22",
        "pickupPoint": "Zone 22",
        "dumpPoint": "Scrap Yard",
        "dropoffPoint": "Zone 22",
        "scrapType": "S18",
        "binLevel": 100,
        "lastPickup": {
            "operator": {
                "_id": "637ed01438a4a4aea6efa9e4",
                "firstName": "Sunflower",
                "lastName": "Marie"
            },
            "bin": {
                "_id": "659dca0f1079bb10c740af2e",
                "name": "22",
                "pickupPoint": "Zone 22",
                "dumpPoint": "Scrap Yard",
                "dropoffPoint": "Zone 22",
                "scrapType": "S18",
                "binLevel": 100
            },
            "pickupRef": "W0000009",
            "status": "complete",
            "type": "scheduled",
            "truckName": "Skip Truck 04",
            "endTime": "2024-01-20T01:50:00.000Z",
            "events": [
                {
                    "startTime": "2024-01-20T01:40:00.000Z",
                    "type": "transitToPickup",
                    "endTime": "2024-01-20T01:45:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b59"
                },
                {
                    "startTime": "2024-01-20T01:45:00.000Z",
                    "type": "pickedUp",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5a"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "transitToDump",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5b"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "dumped",
                    "endTime": "2024-01-20T01:55:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5c"
                },
                {
                    "startTime": "2024-01-20T01:55:00.000Z",
                    "type": "transitToDropoff",
                    "endTime": "2024-01-20T01:15:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5d"
                },
                {
                    "startTime": "2024-01-10T02:20:00.000Z",
                    "type": "delivered",
                    "endTime": "2024-01-10T02:35:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5e"
                }
            ]
        }
    },

    {
        "_id": "659dca0f1079bb10c740af2e",
        "name": "22",
        "pickupPoint": "Zone 22",
        "dumpPoint": "Scrap Yard",
        "dropoffPoint": "Zone 22",
        "scrapType": "S18",
        "binLevel": 0,
        "lastPickup": {
            "operator": {
                "_id": "637ed01438a4a4aea6efa9e4",
                "firstName": "Sunflower",
                "lastName": "Marie"
            },
            "pickupRef": "W0000009",
            "status": "complete",
            "type": "scheduled",
            "truckName": "Skip Truck 04",
            "endTime": "2024-01-20T01:50:00.000Z",
            "events": [
                {
                    "startTime": "2024-01-20T01:40:00.000Z",
                    "type": "transitToPickup",
                    "endTime": "2024-01-20T01:45:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b59"
                },
                {
                    "startTime": "2024-01-20T01:45:00.000Z",
                    "type": "pickedUp",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5a"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "transitToDump",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5b"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "dumped",
                    "endTime": "2024-01-20T01:55:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5c"
                },
                {
                    "startTime": "2024-01-20T01:55:00.000Z",
                    "type": "transitToDropoff",
                    "endTime": "2024-01-20T01:15:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5d"
                },
                {
                    "startTime": "2024-01-10T02:20:00.000Z",
                    "type": "delivered",
                    "endTime": "2024-01-10T02:35:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5e"
                }
            ]
        }
    },

    {
        "_id": "659dca0f1079bb10c740af2e",
        "name": "22",
        "pickupPoint": "Zone 22",
        "dumpPoint": "Scrap Yard",
        "dropoffPoint": "Zone 22",
        "scrapType": "S18",
        "binLevel": 0,
        "lastPickup": {
            "operator": {
                "_id": "637ed01438a4a4aea6efa9e4",
                "firstName": "Sunflower",
                "lastName": "Marie"
            },
            "pickupRef": "W0000009",
            "status": "complete",
            "type": "scheduled",
            "truckName": "Skip Truck 04",
            "endTime": "2024-01-20T01:50:00.000Z",
            "events": [
                {
                    "startTime": "2024-01-20T01:40:00.000Z",
                    "type": "transitToPickup",
                    "endTime": "2024-01-20T01:45:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b59"
                },
                {
                    "startTime": "2024-01-20T01:45:00.000Z",
                    "type": "pickedUp",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5a"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "transitToDump",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5b"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "dumped",
                    "endTime": "2024-01-20T01:55:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5c"
                },
                {
                    "startTime": "2024-01-20T01:55:00.000Z",
                    "type": "transitToDropoff",
                    "endTime": "2024-01-20T01:15:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5d"
                },
                {
                    "startTime": "2024-01-10T02:20:00.000Z",
                    "type": "delivered",
                    "endTime": "2024-01-10T02:35:00.000Z",
                    "zone": "Zone 08",
                    "_id": "659dea3251dd018228f95b5e"
                }
            ]
        }
    },

    {
        "_id": "659dca0f1079bb10c740af2e",
        "name": "22",
        "pickupPoint": "Zone 22",
        "dumpPoint": "Scrap Yard",
        "dropoffPoint": "Zone 22",
        "scrapType": "S18",
        "binLevel": 0,
        "lastPickup": {
            "operator": {
                "_id": "637ed01438a4a4aea6efa9e4",
                "firstName": "Sunflower",
                "lastName": "Marie"
            },
            "pickupRef": "W0000009",
            "status": "incomplete",
            "type": "scheduled",
            "truckName": "Skip Truck 04",
            "endTime": "2024-01-20T01:50:00.000Z",
            "events": [
                {
                    "startTime": "2024-01-20T01:40:00.000Z",
                    "type": "transitToPickup",
                    "endTime": "2024-01-20T01:45:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b59"
                },
                {
                    "startTime": "2024-01-20T01:45:00.000Z",
                    "type": "pickedUp",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5a"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "transitToDump",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5b"
                },
            ]
        }
    },

    {
        "_id": "659dca0f1079bb10c740af2e",
        "name": "22",
        "pickupPoint": "Zone 22",
        "dumpPoint": "Scrap Yard",
        "dropoffPoint": "Zone 22",
        "scrapType": "S18",
        "binLevel": 0,
        "lastPickup": {
            "operator": {
                "_id": "637ed01438a4a4aea6efa9e4",
                "firstName": "Sunflower",
                "lastName": "Marie"
            },
            "pickupRef": "W0000009",
            "status": "inProgress",
            "type": "scheduled",
            "truckName": "Skip Truck 04",
            "endTime": "2024-01-20T01:50:00.000Z",
            "events": [
                {
                    "startTime": "2024-01-20T01:40:00.000Z",
                    "type": "transitToPickup",
                    "endTime": "2024-01-20T01:45:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b59"
                },
                {
                    "startTime": "2024-01-20T01:45:00.000Z",
                    "type": "pickedUp",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5a"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "transitToDump",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5b"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "dumped",
                    "endTime": "2024-01-20T01:55:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5c"
                },
                {
                    "startTime": "2024-01-20T01:55:00.000Z",
                    "type": "transitToDropoff",
                    "endTime": "2024-01-20T01:15:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5d"
                },
            ]
        }
    },

    {
        "_id": "659dca0f1079bb10c740af2e",
        "name": "22",
        "pickupPoint": "Zone 22",
        "dumpPoint": "Scrap Yard",
        "dropoffPoint": "Zone 22",
        "scrapType": "S18",
        "binLevel": 0,
        "lastPickup": {
            "operator": {
                "_id": "637ed01438a4a4aea6efa9e4",
                "firstName": "Sunflower",
                "lastName": "Marie"
            },
            "pickupRef": "W0000009",
            "status": "inProgress",
            "type": "scheduled",
            "truckName": "Skip Truck 04",
            "endTime": "2024-01-20T01:50:00.000Z",
            "events": [
                {
                    "startTime": "2024-01-20T01:40:00.000Z",
                    "type": "transitToPickup",
                    "endTime": "2024-01-20T01:45:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b59"
                },
                {
                    "startTime": "2024-01-20T01:45:00.000Z",
                    "type": "pickedUp",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b5a"
                },
                {
                    "startTime": "2024-01-20T01:50:00.000Z",
                    "type": "transitToDump",
                    "endTime": "2024-01-20T01:50:00.000Z",
                    "zone": "Scrap Yard",
                    "_id": "659dea3251dd018228f95b5b"
                },
            ]
        }
    },

    {
        "_id": "659dca0f1079bb10c740af2e",
        "name": "22",
        "pickupPoint": "Zone 22",
        "dumpPoint": "Scrap Yard",
        "dropoffPoint": "Zone 22",
        "scrapType": "S18",
        "binLevel": 0,
        "lastPickup": {
            "operator": {
                "_id": "637ed01438a4a4aea6efa9e4",
                "firstName": "Sunflower",
                "lastName": "Marie"
            },
            "pickupRef": "W0000009",
            "status": "inProgress",
            "type": "scheduled",
            "truckName": "Skip Truck 04",
            "endTime": "2024-01-20T01:50:00.000Z",
            "events": [
                {
                    "startTime": "2024-01-20T01:40:00.000Z",
                    "type": "transitToPickup",
                    "endTime": "2024-01-20T01:45:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b59"
                },
            ]
        }
    },

    {
        "_id": "659dca0f1079bb10c740af2e",
        "name": "22",
        "pickupPoint": "Zone 22",
        "dumpPoint": "Scrap Yard",
        "dropoffPoint": "Zone 22",
        "scrapType": "S18",
        "binLevel": 0,
        "pickupRequest": {
            "time": "2024-01-30T20:23:15.010Z"
        },
        "dropoffOnly": true,
        "lastPickup": {
            "operator": {
                "_id": "637ed01438a4a4aea6efa9e4",
                "firstName": "Sunflower",
                "lastName": "Marie"
            },
            "pickupRef": "W0000009",
            "status": "incomplete",
            "type": "scheduled",
            "truckName": "Skip Truck 04",
            "endTime": "2024-01-20T01:50:00.000Z",
            "events": [
                {
                    "startTime": "2024-01-20T01:40:00.000Z",
                    "type": "transitToPickup",
                    "endTime": "2024-01-20T01:45:00.000Z",
                    "zone": "Zone 22",
                    "_id": "659dea3251dd018228f95b59"
                },
            ]
        }
    },
];