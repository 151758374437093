import { SteelServLogo } from "../../assets/images/index";
import React from "react";
import { Button } from "../../components/Buttons";

const EmailSendMessage = ({ loading, handleSubmitClick, email }) => {
  return (
    <div className="login-view">
      <div className="d-flex bin_open-page-content  m-auto d-flex justify-content-center flex-column">
        <div className="bin_login-logo">
          <img src={SteelServLogo} className="bin_logo" alt="Eye" />
        </div>

        <div className="flex-1">
          <div className="resetPassMessageWrapper">
            <h4 className="text-dark fw-bold resetPassMessage">
              We've sent a link to reset your password to {email}
            </h4>
          </div>
        </div>

        <div>
          <Button
            style={{ boxShadow: "0px 3px 6px #00000029" }}
            onClick={handleSubmitClick}
            title="Send me a temporary password"
            loading={loading}
          >
            <div>Resend Link</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailSendMessage;
