import React, { useContext, useState } from "react";
import EmailSendMessage from "./EmailSendMessage";
import { SteelServLogo } from "../../assets/images/index";
import { Button } from "../../components/Buttons";
import { InputField } from "../../components";
import { emailValidation } from "../../utils/helper";
import { UserContext } from "../../services/userContext";
import { API_METHODS, ENDPOINTS } from "../../utils/constants";
import { setAccessToken } from "../../services/accessToken";
import "./styles.scss";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { ApiHandler } = useContext(UserContext);

  const forgotPassword = async (data) => {
    const response = await ApiHandler({
      endPoint: ENDPOINTS.forgotPassword,
      reqParam: data,
      method: API_METHODS.POST,
      responseType: "json",
      withCredentials: false,
    });
    return response;
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    const emailErrorMsg = emailValidation(email);
    setErrorMsg(emailErrorMsg);
    if (emailErrorMsg) {
      return;
    }
    setLoading(true);
    try {
      await forgotPassword({ email, isAccessedFromBinOwnerApp: true });
      setLoading(false);
      setIsSuccess(true);
      setAccessToken("");
      localStorage.setItem("accessToken", "");
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };
  return (
    <div className="login-view">
      {isSuccess ? (
        <EmailSendMessage
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          email={email}
          handleSubmitClick={handleSubmitClick}
          loading={loading}
        />
      ) : (
        <div className="d-flex bin_open-page-content  m-auto d-flex justify-content-between flex-column">
          <div className="bin_login-logo">
            <img src={SteelServLogo} className="bin_logo" alt="Eye" />
          </div>
          <div className="flex-1">
            <h4 className="text-dark fw-bold login-header">
              Type in your email address to send a reset password link to
            </h4>

            <InputField
              label=""
              labelFont="email"
              value={email}
              onChange={(value) => setEmail(value)}
              className=""
              style={{}}
              isError={errorMsg ? true : false}
              type="email"
              isDisabled={false}
            />
            <p className="bin_error-msg">{errorMsg}</p>
          </div>
          <div>
            <Button
              style={{ boxShadow: "0px 3px 6px #00000029" }}
              onClick={handleSubmitClick}
              loading={loading}
              title="Send me a temporary password"
            >
              <div>Send Link</div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
