import React, { useContext } from 'react';
import { Button, toast } from '../../../../components';
import "./styles.scss";
import { UserContext } from '../../../../services/userContext';
import { API_METHODS, ENDPOINTS } from '../../../../utils/constants';

const CancelPickup = ({ bin, onSave }) => {
    const { ApiHandler } = useContext(UserContext);

    const cancelPickupRequest = async () => {
        try {
            const response = await ApiHandler({
                reqParam: {
                    binName: bin.name
                },
                method: API_METHODS.PUT,
                endPoint: ENDPOINTS.requestPickup,
            });
            if (response.status === 200) {
                onSave();
                toast.success('Details successfully updated');
            }
        } catch (error) {
            toast.error("error?.message");
        }
    }

    return (
        <div className='d-flex flex-column justify-content-between h-100'  >
            <div className='d-flex flex-column  align-items-center justify-content-center flex-1'>
                <div className='bin_cancel_pickup-title mb-3'>Cancel Pickup - Bin {bin.name}</div>
                <div className='bin_cancel_pickup-desc '>Are you sure you want to </div>
                <div className='bin_cancel_pickup-desc '> cancel the pickup </div>
                <div className='bin_cancel_pickup-desc '> request?</div>
            </div>
            <Button className={"mb-5"} onClick={() => { cancelPickupRequest() }}> Confirm</Button>
        </div>
    );
};

export default CancelPickup;
