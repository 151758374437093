import { ArcElement, Chart as ChartJS, } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import "./styles.scss"
import { GoDotFill } from 'react-icons/go';
import { getPickupTotalDuration } from '../../BinHistory/BinHistoryDetail/SubJob';

const colors = ["#47519D", "#54BA9A", "#E09C2F", "#AC4694" ]

const shadowPlugin = {
  id: 'shadow',
  afterDatasetsDraw: function (chart, easing) {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          // Draw the shadow here
          ctx.fillStyle = 'rgba(0, 0, 0, 0.15)'; // This sets the shadow color
          const { startAngle, endAngle, innerRadius, outerRadius, x, y } = element;
          let shadowOffsetX = 1;
          let shadowOffsetY = 0;
          let shadowBlur = 0;

          // Save the context state
          ctx.save();
          // Apply the shadow
          ctx.shadowOffsetX = shadowOffsetX;
          ctx.shadowOffsetY = shadowOffsetY;
          ctx.shadowBlur = shadowBlur;
          ctx.shadowColor = 'rgba(0,0,0,0.25)';

          // Create the arc with the shadow
          ctx.beginPath();
          ctx.arc(x, y, outerRadius - 13, startAngle, endAngle);
          ctx.arc(x, y, innerRadius, endAngle, startAngle, true);
          ctx.closePath();
          ctx.fill();

          // Restore the context state
          ctx.restore();
        });
      }
    });
  }
};

ChartJS.register(ArcElement, shadowPlugin);

const PieChart = ({ data }) => {



  const plugins = [{
    id: 'textInsideDoughnut',
    beforeDraw: function (chart) {
      let width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      let fontSize = 14;
      ctx.font = `bold ${fontSize}px sans-serif`; 
      ctx.textBaseline = "middle";
    
      let text = getPickupTotalDuration(data.binPickup),
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }];

  const totalLoadCount = data.timeline.reduce((total, timeline) => total + (timeline.duration ?? 0), 0);
  const isTotalLoadCountZero = totalLoadCount === 0;

  // Extract the bin names and load counts from the data
  const binNames = data.timeline.map(timeline => `${timeline.title}`);
  const loadCounts = data.timeline.map(timeline => timeline.duration);

  // Setup the chart data
  const chartData = {
    labels: binNames,
    datasets: [
      {
        label: 'Job Cycle Timeline',
        data: isTotalLoadCountZero ? [1] : loadCounts,
        backgroundColor: isTotalLoadCountZero ? ['rgba(211, 211, 211, 0.5)'] :  colors,
        hoverBackgroundColor: colors,
        borderWidth: 0,
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: '60%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true
      }
    }
  };

  return <div key={JSON.stringify(data?.timeline)} className=''>
    <div className='' style={{ maxHeight: "100px" }} >
      <Doughnut data={chartData} options={options} plugins={plugins} />
    </div>


    <div className='mt-4'>
      <div className='mb-3 bin_legend-pie-bold-text d-flex align-items-center '>
        <div className='row d-flex w-100'>
          <div className='col-9 m-0 p-0 pl-4'>
           Total Cycle Duration
          </div>
          <div className='col-3 m-0 p-0'>
            <span className='bin_legend-pie-bold-text'>{getPickupTotalDuration(data.binPickup)}</span>
          </div>
        </div>
      </div>

      {data?.timeline?.map((timeline, index) => {
        return <div className='bin_legend-pie-text d-flex align-items-center '>
          <div className='row d-flex w-100'>
            <div className='col-9 m-0 p-0 pl-4'>
              <GoDotFill size={16} className="mr-2" color={colors[index]} />
              {timeline.title}
            </div>
            <div className='col-3 m-0 p-0'>
              <span className='bin_legend-pie-bold-text'>{timeline.time}</span>
            </div>
          </div>
        </div>
      })}
    </div>
  </div>;
};

export default PieChart;
