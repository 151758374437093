import moment from "moment";
import { GoDotFill } from "react-icons/go";
import {
  BinBlue,
  BinGreen,
  BinGrey,
  BinRed,
  BinYellow,
} from "../assets/images";
import { forEach, includes, isEmpty, isUndefined } from "lodash";
import { logger } from "./logger";

export const getBinColorByLevel = (binLevel) => {
  if (binLevel === 0) {
    return { backgroundColor: "#E2E2E2", borderColor: "#7B7B7B" };
  } else if (binLevel > 0 && binLevel <= 25) {
    return { backgroundColor: "#64E5D1", borderColor: "#21937D" };
  } else if (binLevel > 25 && binLevel <= 50) {
    return { backgroundColor: "#C4ED3D", borderColor: "#749B3D" };
  } else if (binLevel > 50 && binLevel <= 75) {
    return { backgroundColor: "#FFAF36", borderColor: "#C16F00" };
  } else if (binLevel > 75 && binLevel <= 100) {
    return { backgroundColor: "#FF6A6A", borderColor: "#BE3737" };
  } else {
    return { backgroundColor: "#E2E2E2", borderColor: "#7B7B7B" };
  }
};

export const getBinIconByLevel = (binLevel, width, height) => {
  if (binLevel === 0) {
    return <BinGrey width={width} height={height} />;
  } else if (binLevel > 0 && binLevel <= 25) {
    return <BinBlue width={width} height={height} />;
  } else if (binLevel > 25 && binLevel <= 50) {
    return <BinGreen width={width} height={height} />;
  } else if (binLevel > 50 && binLevel <= 75) {
    return <BinYellow width={width} height={height} />;
  } else if (binLevel > 75 && binLevel <= 100) {
    return <BinRed width={width} height={height} />;
  } else {
    return <BinGrey width={width} height={height} />;
  }
};

const commonStatusLabels = {
  "pick-up-scheduled": "Pick-up Scheduled",
  "picked-up": "Picked-up",
  dumped: "Dumped",
  "job-complete": "Job Complete",
  "back-at-source": "Back at Source",
  "job-incomplete": "Job Incomplete",
  "bin-missing": "Bin Missing",
  "pick-up-requested": "Pick-up Requested",
  "status-unavailable": "Status unavailable",
};

const commonStatusColors = {
  "pick-up-scheduled": "#C3F3F5",
  "picked-up": "#E4FAA7",
  dumped: "#D3D5FC",
  "job-complete": "#C7F5C3",
  "back-at-source": "#E2E2E2",
  "job-incomplete": "#FFDAD3",
  "bin-missing": "#FED3DF",
  "pick-up-requested": "#FFDAD3",
  "status-unavailable": "#E2E2E2",
};

export const BIN_STATUS = {
  PICK_UP_SCHEDULED: "pick-up-scheduled",
  PICKED_UP: "picked-up",
  DUMPED: "dumped",
  JOB_COMPLETE: "job-complete",
  BACK_AT_SOURCE: "back-at-source",
  JOB_INCOMPLETE: "job-incomplete",
  BIN_MISSING: "bin-missing",
  PICK_UP_REQUESTED: "pick-up-requested",
  STATUS_UNAVAILABLE: "status-unavailable",
};

export const BUTTON_ACTION = {
  UPDATE_LEVEL: "updateLevel",
  TRACK_BIN: "trackBin",
  UPDATE_HAZARDS: "updateHazards",
  BIN_HISTORY: "binHistory",
  REQUEST_PICKUP: "requestPickup",
  ADD_HAZARDS: "addHazards",
  HISTORY: "history",
  CANCEL_PICKUP: "cancelPickup",
};

const commonStatusButtons = {
  [BIN_STATUS.PICK_UP_SCHEDULED]: [
    BUTTON_ACTION.UPDATE_LEVEL,
    BUTTON_ACTION.TRACK_BIN,
    BUTTON_ACTION.UPDATE_HAZARDS,
    BUTTON_ACTION.BIN_HISTORY,
  ],
  [BIN_STATUS.PICKED_UP]: [
    BUTTON_ACTION.TRACK_BIN,
    BUTTON_ACTION.UPDATE_HAZARDS,
    BUTTON_ACTION.BIN_HISTORY,
  ],
  [BIN_STATUS.DUMPED]: [
    BUTTON_ACTION.TRACK_BIN,
    BUTTON_ACTION.UPDATE_HAZARDS,
    BUTTON_ACTION.BIN_HISTORY,
  ],
  [BIN_STATUS.JOB_COMPLETE]: [
    BUTTON_ACTION.UPDATE_LEVEL,
    BUTTON_ACTION.REQUEST_PICKUP,
    BUTTON_ACTION.UPDATE_HAZARDS,
    BUTTON_ACTION.BIN_HISTORY,
  ],
  [BIN_STATUS.BACK_AT_SOURCE]: [
    BUTTON_ACTION.UPDATE_LEVEL,
    BUTTON_ACTION.REQUEST_PICKUP,
    BUTTON_ACTION.ADD_HAZARDS,
    BUTTON_ACTION.BIN_HISTORY,
  ],
  [BIN_STATUS.JOB_INCOMPLETE]: [
    BUTTON_ACTION.TRACK_BIN,
    BUTTON_ACTION.UPDATE_HAZARDS,
    BUTTON_ACTION.BIN_HISTORY,
  ],
  [BIN_STATUS.BIN_MISSING]: [
    BUTTON_ACTION.HISTORY,
    BUTTON_ACTION.UPDATE_HAZARDS,
    BUTTON_ACTION.BIN_HISTORY,
  ],
  [BIN_STATUS.PICK_UP_REQUESTED]: [
    BUTTON_ACTION.UPDATE_LEVEL,
    BUTTON_ACTION.CANCEL_PICKUP,
    BUTTON_ACTION.UPDATE_HAZARDS,
    BUTTON_ACTION.BIN_HISTORY,
  ],
  [BIN_STATUS.STATUS_UNAVAILABLE]: [],
};

export const calculateDuration = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return {
    // time: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} h`,
    time: `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} h`,
    duration: duration.asSeconds(),
  };
};

export const getDifferenceTime = (bin) => {
  if (!bin?.pickupRequest?.time) {
    return "";
  }
  // Calculate the difference in milliseconds
  const now = moment.utc();
  const end = moment.utc(bin.pickupRequest?.time);
  const duration = moment.duration(now.diff(end));
  let hours = duration.hours();
  let minutes = duration.minutes();
  // const seconds = duration.seconds();
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " hrs";
};

export const getStatusLabel = (bin, displayHistoricalStatus = false) => {
  let commonStatus = displayHistoricalStatus
    ? getHistoricalBinStatus(bin)
    : getCommonBinStatus(bin);
  let label = commonStatusLabels[commonStatus];
  if (commonStatus === "pick-up-requested") {
    label += " - " + getDifferenceTime(bin) + " ago";
  }
  return label;
};

export const getStatusLabelColor = (bin, displayHistoricalStatus = false) => {
  const status = displayHistoricalStatus
    ? getHistoricalBinStatus(bin)
    : getCommonBinStatus(bin);
  return commonStatusColors[status];
};

export const getRequiredBinButtons = (bin) => {
  let myCommonStatusButtons = [
    ...(commonStatusButtons[getCommonBinStatus(bin)] || []),
  ];

  if (
    myCommonStatusButtons.includes(BUTTON_ACTION.ADD_HAZARDS) &&
    bin?.hazards
  ) {
    const index = myCommonStatusButtons.indexOf(BUTTON_ACTION.ADD_HAZARDS);
    if (index !== -1) {
      myCommonStatusButtons.splice(index, 1);
    }
    myCommonStatusButtons.push(BUTTON_ACTION.UPDATE_HAZARDS);
  }

  if (
    myCommonStatusButtons.includes(BUTTON_ACTION.UPDATE_HAZARDS) &&
    !bin?.hazards
  ) {
    const index = myCommonStatusButtons.indexOf(BUTTON_ACTION.UPDATE_HAZARDS);
    if (index !== -1) {
      myCommonStatusButtons.splice(index, 1);
    }
    myCommonStatusButtons.push(BUTTON_ACTION.ADD_HAZARDS);
  }

  return myCommonStatusButtons;
};

export const getCommonBinStatus = (bin) => {
  if (bin) {
    if (bin.lastPickup?.status === "inProgress") {
      if (
        bin.lastPickup?.events?.length &&
        bin.lastPickup.events.find(
          (event) => event.type === "dumped" && event.endTime
        )
      ) {
        return "dumped";
      } else if (
        bin.lastPickup?.events?.length &&
        bin.lastPickup.events.find(
          (event) => event.type === "pickedUp" && event.endTime
        )
      ) {
        return "picked-up";
      } else return "pick-up-scheduled";
    }

    if (bin.pickupRequest?.time) {
      return "pick-up-requested";
    }

    if (
      bin.lastPickup?.events?.length &&
      bin.lastPickup?.events[bin.lastPickup?.events?.length - 1]?.type ===
        "delivered" &&
      bin.dropoffPoint !==
        bin.lastPickup?.events[bin.lastPickup?.events?.length - 1]?.zone
    ) {
      return "bin-missing";
    }

    if (bin.lastPickup?.status === "complete") {
      if (bin.binLevel === 0) {
        return "job-complete";
      }
      if (bin.binLevel > 0) {
        return "back-at-source";
      }
    }
    if (bin.lastPickup?.status === "incomplete") {
      if (bin.binLevel === 0) {
        return "job-incomplete";
      }
      if (
        bin.lastPickup?.events?.length &&
        bin.lastPickup?.events[bin.lastPickup?.events?.length - 1]?.type !==
          "delivered"
      ) {
        return "job-incomplete";
      }
      if (bin.binLevel > 0) {
        return "back-at-source";
      }
    }
  }

  return "status-unavailable";
};

export const getHistoricalBinStatus = (bin) => {
  if (bin) {
    if (bin.lastPickup?.status === "inProgress") {
      if (
        bin.lastPickup?.events?.length &&
        bin.lastPickup.events.find(
          (event) => event.type === "dumped" && event.endTime
        )
      ) {
        return "dumped";
      } else if (
        bin.lastPickup?.events?.length &&
        bin.lastPickup.events.find(
          (event) => event.type === "pickedUp" && event.endTime
        )
      ) {
        return "picked-up";
      } else return "pick-up-scheduled";
    }
    if (bin.lastPickup?.status === "complete") {
      return "job-complete";
    }
    if (bin.lastPickup?.status === "incomplete") {
      return "job-incomplete";
    }
  }
  return "status-unavailable";
};

export const convertMins = (minutes) => {
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;
  let timeString = "";
  if (hrs === 1) timeString = "1 hour ";
  else if (hrs) timeString = `${hrs} hours `;
  if (mins === 1) timeString += "1 min";
  else if (mins) timeString += `${mins} mins`;
  return timeString;
};

export const convertTruckStatusToColor = (status) => {
  switch (status) {
    case "Pumping":
      return "green";
    case "Inactive":
      return "red";
    default:
    case "Active":
      return "orange";
  }
};

export const convertUserStatusToColor = (status) => {
  switch (status) {
    case "Invited":
      return "orange";
    case "Inactive":
      return "red";
    default:
    case "Active":
      return "green";
  }
};

export const getFirstLetter = (name) => {
  return name?.substring(0, 1).toUpperCase();
};

export const startOfDay = (date) => {
  const parts = Intl.DateTimeFormat("en-NZ", {
    timeZone: "Pacific/Auckland",
    hourCycle: "h23",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).formatToParts(new Date(date));

  const hour = parseInt(parts?.find((i) => i?.type === "hour")?.value);
  const minute = parseInt(parts?.find((i) => i.type === "minute")?.value);
  const second = parseInt(parts?.find((i) => i.type === "second")?.value);
  return new Date(
    1000 *
      Math.floor(
        (new Date(date).getTime() -
          hour * 3600000 -
          minute * 60000 -
          second * 1000) /
          1000
      )
  );
};

export const endOfDay = (...args) =>
  new Date(startOfDay(args[0]).getTime() + 86399999);

export const groupBy = (list, key) => {
  return list.reduce((prev, curr) => {
    return {
      ...prev,
      [curr[key]]: [...(prev[key] || []), curr],
    };
  }, {});
};

export function getOrdinal(n) {
  if (typeof n === "string") {
    n = parseInt(n);
  }
  let ord = ["st", "nd", "rd"];
  let exceptions = [11, 12, 13];
  let nth =
    ord[(n % 10) - 1] === undefined || exceptions.includes(n % 100)
      ? "th"
      : ord[(n % 10) - 1];
  return n + nth;
}

export const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const formatDateToISO = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const groupByDate = (list, key) => {
  return list.reduce((prev, curr) => {
    return {
      ...prev,
      [formatDate(curr[key])]: [...(prev[formatDate(curr[key])] || []), curr],
    };
  }, {});
};

export const getTime = (time) => {
  return new Date(time).toLocaleTimeString("en-NZ", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let res = "";

  if (hours > 0 || minutes > 0) {
    if (hours === 1) {
      res += "1 hour ";
    } else if (hours > 0) {
      res += hours + " hour ";
    }
    if (minutes > 0) {
      res += minutes + " min";
    }
  } else {
    res += "0 min";
  }

  return res;
};

export const emailValidation = (email = "") => {
  const trimmed = email.trim();
  if (trimmed === "") {
    return "Please enter your registered email address";
  } else if (
    !new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(trimmed)
  ) {
    return "Please enter a valid email address";
  }
  return "";
};

export const passwordValidation = (pword) => {
  const trimmed = pword.trim();
  if (trimmed === "") {
    return "Please enter password";
  }
  return "";
};

export const lengthValidation = (value, fieldName) => {
  const trimmed = value.trim();
  if (trimmed === "") {
    return `Please enter ${fieldName}`;
  } else if (trimmed.length < 3) {
    return `${getFirstLetter(fieldName).toUpperCase()}${fieldName.slice(
      1
    )} must have at least 3 letters`;
  }
  return "";
};

export const newPasswordValidation = (pword, confirmPword) => {
  const trimmedPword = pword.trim();
  const trimmedConfirm = confirmPword.trim();
  const errors = { password: "", confirmPassword: "" };
  if (!trimmedPword) errors.password = "Please enter password";
  else if (trimmedPword.length < 6) {
    errors.password = "Password must be at least 6 characters";
  }

  if (!trimmedConfirm) errors.confirmPassword = "Please enter confirm password";
  else if (trimmedPword && trimmedConfirm !== trimmedPword) {
    errors.confirmPassword = "Passwords do not match";
  }
  return errors;
};

export const currentDate = (date) => {
  let dayName = new Date(date).toLocaleDateString("en-NZ", { weekday: "long" });
  let monthName = new Date(date).toLocaleDateString("en-NZ", { month: "long" });
  let day = new Date(date).toLocaleDateString("en-NZ", {
    day: "numeric",
  });
  let dayFormatTh = getOrdinal(day);

  return dayName + " " + dayFormatTh + " " + monthName;
};

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const minuteToHour = (minutes) => Math.round(minutes / 60);

export const formatDateToDDMMYYYY = (date) => {
  if (!date) return "";
  const convertedToDate = new Date(date);
  const day = String(convertedToDate.getDate()).padStart(2, "0");
  const month = String(convertedToDate.getMonth() + 1).padStart(2, "0");
  const year = convertedToDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getZones = (job, filteredZones) => {
  const zones = new Set(job.pump_events.map((e) => e.zone).filter(Boolean));
  if (job?.zone?.zone) {
    zones.add(job.zone.zone);
  }
  let filteredZonesArray = filteredZones.filter((zone) =>
    [...zones].includes(zone)
  );
  if (!filteredZonesArray.length) filteredZonesArray = [...zones];
  if (filteredZonesArray.length > 1) {
    return `Zones ${filteredZonesArray.join(", ")}`;
  }
  if (filteredZonesArray.length === 1) {
    return `Zone ${filteredZonesArray[0]}`;
  }
  return "-";
};

export const capitaliseFirstLetter = (word) => {
  if (!word) {
    return "";
  }
  const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
  return capitalized;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const convertCodeListToNames = (entireList, selected) => {
  if (!selected) return "None";
  if (selected.length === 0) return "None";

  const filteredList = entireList
    .filter(({ value, code }) => selected.includes(value ?? code))
    .map(({ name }) => name);
  if (filteredList.length === 0) return "None";
  if (filteredList.length === entireList.length) return "All";
  else {
    return filteredList.join(", ");
  }
};

const formateDateStringToSimpleDate = (date) => {
  const formattedDate = date.toLocaleDateString("en-NZ", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "Pacific/Auckland", // New Zealand time zone
  });

  return formattedDate.split("/").reverse().join().replace(/\,/g, "-");
};

const getDaysInMonth = (month, year) => {
  if (!month) {
    return [];
  }
  let date = new Date(year, month - 1, 1);
  let days = [];

  while (date.getMonth() + 1 === parseInt(month)) {
    days.push(formateDateStringToSimpleDate(new Date(date)));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const getSundayAfterRequestedDate = (date) => {
  let lastDate = new Date(date);
  const dayOfWeek = lastDate.getDay();
  const daysToAdd = dayOfWeek === 0 ? 0 : 7 - dayOfWeek; // Adjust for Sunday being 0 and Monday being 1
  lastDate.setDate(lastDate.getDate() + daysToAdd);
  logger.debug({ lastDate, mom: moment(lastDate).format("DD/MM/YYYY") });
  return lastDate;
};

export const getMondayBeforeRequestedDate = (date) => {
  let lastDate = new Date(date);
  const dayOfWeek = lastDate.getDay();
  const daysToSubtract = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust for Sunday being 0 and Monday being 1
  lastDate.setDate(lastDate.getDate() + daysToSubtract); // add as will be a negative number
  return lastDate;
};

const getPastDays = (date, count = 7) => {
  if (!date) {
    return [];
  }
  const days = [];
  let current = date;
  let counter = count;
  let lastDate = getSundayAfterRequestedDate(current);

  while (counter > 0) {
    const formattedDate = lastDate.toLocaleDateString("en-NZ", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "Pacific/Auckland", // New Zealand time zone
    });
    days.push(formattedDate.split("/").reverse().join().replace(/\,/g, "-"));
    counter--;
    lastDate.setDate(lastDate.getDate() - 1);
  }
  return days;
};

const getPastDates = (date, dayMonth) => {
  let result = [];

  if (dayMonth === "month") {
    result = getDaysInMonth(date.split("-")[1], date.split("-")[0]);
  } else {
    result = getPastDays(date, 7);
  }
  result.sort((a, b) => a - b);
  return result;
};
export const formatWeeklyData = (date, dayMonth, loadData) => {
  const dates = getPastDates(date, dayMonth); // Assuming this returns an array of date strings

  let dataResponse =
    loadData?.map((loadForDay) => {
      // Initialize an object with all dates set to 0 load count
      const initialLoadCountsPerDay = dates.reduce((acc, date) => {
        acc[date] = 0; // Initialize all dates with 0
        return acc;
      }, {});

      // Populate with actual load counts where available
      const loadCountsPerDay = loadForDay?.loadCountsPerDay.reduce(
        (acc, { date, loadCount }) => {
          if (dates.includes(date)) {
            acc[date] = loadCount;
          }
          return acc; // Always return the accumulator
        },
        initialLoadCountsPerDay
      ); // Start with the initial object

      return {
        ...loadForDay,
        loadCountsPerDay,
      };
    }) || [];

  // Ensure the loadCountsPerDay objects' keys are sorted
  dataResponse = dataResponse.map((item) => ({
    ...item,
    loadCountsPerDay: Object.keys(item.loadCountsPerDay)
      .sort()
      .reduce((obj, key) => {
        obj[key] = item.loadCountsPerDay[key];
        return obj;
      }, {}),
  }));

  return dataResponse;
};

export const colors = [
  "#626BBC",
  "#F29F1A",
  "#00C896",
  "#FF5733",
  "#D1A309",
  "#33A3FF",
  "#7D3C98",
  "#FF3366",
  "#14A76C",
  "#FFBD33",
  "#6666FF",
  "#FF5733",
  "#A309D1",
  "#33A3FF",
  "#98A63C",
  "#3366FF",
  "#6C14A7",
  "#FFBD33",
  "#33A3FF",
  "#D133A3",
  "#4C7D61",
  "#A733FF",
  "#FF3333",
  "#D9A309",
  "#33A3FF",
  "#33FFA7",
  "#A733D9",
  "#33A3FF",
  "#FF5733",
];

export const getHistoryStatusLabels = (status) => {
  switch (status) {
    case "incomplete":
      return (
        <div className="d-flex justify-content-center align-items-center">
          <GoDotFill size={14} className="mt" color="#D54A4A" />
          <span className="bin_history_status-text">Incomplete</span>
        </div>
      );
    case "complete":
      return (
        <div className="d-flex  justify-content-center align-items-center">
          <GoDotFill size={14} className="mt" color="#21A865" />
          <span className="bin_history_status-text">Complete</span>
        </div>
      );
    case "inProgress":
      return (
        <div className="d-flex  justify-content-center align-items-center">
          <GoDotFill size={14} className="" color="#3E97FF" />
          <span className="bin_history_status-text"> In Progress</span>
        </div>
      );
    default:
      return (
        <div className="d-flex  justify-content-center align-items-center">
          <GoDotFill size={14} className="" color="#3E97FF" />
          <span className="bin_history_status-text"> No Status</span>
        </div>
      );
  }
};

export const getHistoryTypeLabels = (type) => {
  switch (type) {
    case "scheduled":
      return "Scheduled";
    case "unscheduled":
      return "Unscheduled";
    default:
      return capitaliseFirstLetter(type);
  }
};

export const getDurationAgo = (endTime) => {
  if (!endTime) return "";
  return `(${getDurationAgoWithoutParentheses(endTime)})`;
};

export const getDurationAgoWithoutParentheses = (endTime) => {
  if (!endTime) return "-";

  // Calculate the difference in milliseconds
  const now = moment();
  const end = moment(endTime);
  const duration = moment.duration(now.diff(end));

  // Check if the difference is greater than one day
  if (duration.asDays() >= 1) {
    // Return formatted string for days
    return `${Math.ceil(duration.asDays())} days ago`;
  } else {
    // Return formatted string for hours and minutes
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0"); // Zero-pad minutes
    return `${hours}:${minutes} h ago`;
  }
};

/**
 * This function checks if the current platform is a Mac.
 * It uses the 'platform' property of the 'navigator' object which is a part of the Window interface.
 * The 'platform' = "MacIntel" | "Win32" | "Linux x86_64" | "Linux armv81" | "iPhone"
 * The function checks if the returned string contains "Mac" to determine if the platform is a Mac.
 * @returns {boolean} - returns true if the platform is a Mac, false otherwise.
 */
export const isMacPlatform = () => {
  const platform = window.navigator.platform;
  if (platform === "iPhone") return true;
  else return includes(platform, "Mac");
};

export const getTotalLoads = (dashboardLoads, isMonthActive, date) => {
  let binSegregatedLoads = [];
  if (!isUndefined(dashboardLoads) && !isEmpty(dashboardLoads.loads)) {
    /*
      This will be used in the pie chart to display the loads segregated by bins
      Eg: Bin 29: 2 Loads
          Bin 31: 1 Loads
          Bin 23: 1 Loads
    */

    forEach(dashboardLoads.loads, (load) => {
      const binLoad = {
        binName: load.binName,
        loadCount: load.loadCountsPerDay.reduce((accumulator, binLoad) => {
          if (isMonthActive || date === binLoad.date) {
            accumulator += binLoad.loadCount;
          }
          return accumulator;
        }, 0),
      };

      binSegregatedLoads.push(binLoad);
    });

    /*
      This will be used to display the total Bin loads in the beginiing of the screen
      Eg: Total Bin Loads: 5 (LDS) 
    */
    const totalLoads = binSegregatedLoads.reduce(
      (accumulator, binLoad) => (accumulator += binLoad.loadCount),
      0
    );

    return {
      binLoads: binSegregatedLoads,
      totalLoadCount: totalLoads,
    };
  } else
    return {
      binLoads: binSegregatedLoads,
      totalLoadCount: 0,
    };
};
