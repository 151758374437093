import React from 'react';
import { LabelWithLine } from '../../../../../components/index.js';
import "./styles.scss";
import { Dot, HistoryTimeline } from '../../Charts/index.js';
import { Dropdown } from "react-bootstrap";

const LegendItem = ({ dotStyles, active, type, status, label }) => (
    <Dropdown.Item className="d-flex align-items-center justify-content-start" disabled>
        <Dot
            Dotstyles={dotStyles}
            active={active}
            type={type}
            status={status}
        />
        <div className="bin_timeline_label">
            {label}
        </div>
    </Dropdown.Item>
);



const SubJobTimeline = ({ historyDetail }) => {

    return (
        <>
            <LabelWithLine title={""} />
            <div className='d-flex justify-content-end'>
                <Dropdown className="d-inline">
                    <Dropdown.Toggle
                        bsPrefix="custom-toggle"
                        id="legend-dropdown-toggle"
                        className="bin_custom-dropdown-toggle"
                    >
                        Legend
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='bin_dropdown' bsPrefix="dropdown-menu dropdown-menu-m">
                        <LegendItem
                            dotStyles={{ marginLeft: -4, marginRight: "16px" }}
                            active={true}
                            type="basic"
                            status={"completed"}
                            label="Completed"
                        />
                        <LegendItem
                            dotStyles={{ marginLeft: -4, marginRight: "16px" }}
                            active={false}
                            type="delay"
                            status={"upcoming"}
                            label="Upcoming/Incomplete"
                        />
                        <LegendItem
                            dotStyles={{ marginLeft: -4, marginRight: "16px" }}
                            active={true}
                            type="delay"
                            status={"current"}
                            label="Current"
                        />
                        <LegendItem
                            dotStyles={{ marginLeft: -4, marginRight: "16px" }}
                            active={true}
                            type="delay"
                            status={"disconnected"}
                            label="No Connection"
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className='mb-5'>
                <HistoryTimeline 
                    historyDetail={historyDetail}
                />
            </div>

        </>
    );
};

export default SubJobTimeline;

