import React from 'react';
import "./styles.scss";

const Header = ({ left = <></>, right = <></>, className = "", label = "", borderClass = "" }) => {
    return (
        <div className={`d-flex bin_header flex-row w-100 ${className} ${borderClass}`}>
            {left}
            <div className='bin_header-content'>
                {label}
            </div>
            {right}
        </div >
    );
};

export default Header;
