import React, { useContext, useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import {
  BottomModal,
  Button,
  DatePicker,
  Header,
  LoadingScreen,
  toast,
} from "../../../../components";
import BinHistoryDetail from "./BinHistoryDetail";
import { HistoryTile } from "./components";
import "./styles.scss";

import moment from "moment";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { UserContext } from "../../../../services/userContext";
import { API_METHODS, ENDPOINTS } from "../../../../utils/constants";
import {
  getMondayBeforeRequestedDate,
  getSundayAfterRequestedDate,
} from "../../../../utils/binHelper";

const AccordionItem = ({ header, children, isOpen, onToggle }) => {
  return (
    <div>
      <div
        className="bin_accordion-header"
        onClick={onToggle} // Use the passed onToggle function
      >
        <span className="m-0 p-0 bin_accordion-header-text">{header}</span>
        <span>
          {isOpen ? <IoChevronUp size={20} /> : <IoChevronDown size={20} />}
        </span>
      </div>
      {isOpen && <div className="bin_accordion-body">{children}</div>}
    </div>
  );
};

const BinHistory = ({ onClose, bin }) => {
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [actionModal, setActionModal] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [openAccordion, setOpenAccordion] = useState(null);
  const { ApiHandler, userInfo } = useContext(UserContext);
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(false);

  // this function group history response by date and bin
  const prepareHistoryResponse = (unformattedData) => {
    const formattedDataMap = {};

    // Iterate over each item in the unformatted data
    unformattedData.forEach((item) => {
      const binNumber = item.bin;
      const date = item.date;

      // Check if the bin number already exists in the formatted data map
      if (!formattedDataMap[binNumber]) {
        // If not, create a new entry for this bin number
        formattedDataMap[binNumber] = {
          bin: binNumber,
          binHistory: [],
        };
      }

      // Iterate over the bin history and add the date to each entry
      item.binHistory.forEach((historyItem) => {
        const updatedHistoryItem = { ...historyItem, date: date };
        formattedDataMap[binNumber].binHistory.push(updatedHistoryItem);
      });
    });

    // Convert the formatted data map to an array
    const formattedData = Object.values(formattedDataMap).map((binItem) => {
      // Sort the bin history so the latest endTime comes first
      binItem.binHistory.sort(
        (a, b) => new Date(b.endTime) - new Date(a.endTime),
      );
      return binItem;
    });

    return formattedData;
  };

  const fetchHistoryDetails = async () => {
    setLoading(true);
    try {
      const startOfWeek = getMondayBeforeRequestedDate(date);
      const endOfWeek = getSundayAfterRequestedDate(date);
      const startDate = moment(startOfWeek).format("YYYY-MM-DD");
      const endDate = moment(endOfWeek).format("YYYY-MM-DD");
      const response = await ApiHandler({
        method: API_METHODS.GET,
        endPoint: `${ENDPOINTS.supervisorHistory}?startDate=${startDate}&endDate=${endDate}&supervisorEmail=${userInfo?.email}`,
      });

      if (response.status === 200) {
        setDashboardData(prepareHistoryResponse(response.data));
      }
    } catch (error) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHistoryDetails();
  }, [date]);

  const toggleAccordion = (binName) => {
    setOpenAccordion(openAccordion === binName ? null : binName);
  };

  useEffect(() => {
    setOpenAccordion(bin?.name);
  }, [bin?.name]);

  return (
    <div className=" w-100  ">
      <Header
        className=""
        left={
          <div
            onClick={() => {
              onClose();
            }}
            className="bin_header-content d-flex align-items-center"
          >
            <MdOutlineKeyboardArrowLeft size={28} />
            Bin History
          </div>
        }
        right={
          <div
            onClick={() => {
              setActionModal(true);
            }}
          >
            {" "}
            <BsThreeDotsVertical size={24} />{" "}
          </div>
        }
      />

      <div className="bin_header-border pl-4">
        <DatePicker
          value={date}
          onChange={(v) => setDate(v)}
          style={{ marginBottom: "10px" }}
        />
      </div>

      {loading ? (
        <div className="pt-5">
          <LoadingScreen />
        </div>
      ) : (
        <div className="bin_history-grid-container">
          {dashboardData.map((currentBin, index) => {
            return (
              <AccordionItem
                key={index}
                header={`Bin ${currentBin.bin}`}
                isOpen={openAccordion === currentBin.bin}
                onToggle={() => toggleAccordion(currentBin.bin)}
              >
                <div>
                  {/* const startDate = moment(date).subtract(7, 'days').format('YYYY-MM-DD');
                            const endDate = date;  */}
                  <div className="bin_history-date-card">
                    <em className="bin_history-date-title">
                      {" "}
                      <b>Week:</b>{" "}
                      {moment(getMondayBeforeRequestedDate(date)).format(
                        "DD/MM/YYYY",
                      )}{" "}
                      -{" "}
                      {moment(getSundayAfterRequestedDate(date)).format(
                        "DD/MM/YYYY",
                      )}
                    </em>
                  </div>
                  {currentBin?.binHistory?.map((history, historyIndex) => {
                    return (
                      <div
                        className={`bin_history-tile-container ${
                          moment(history?.date, "DD-MM-YYYY")?.format(
                            "DD/MM/YYYY",
                          ) == moment(date).format("DD/MM/YYYY")
                            ? " bin_history-tile-container-today "
                            : ""
                        }`}
                        key={`${historyIndex}${index}`}
                      >
                        {(historyIndex == 0 ||
                          (historyIndex != 0 &&
                            history?.date !=
                              currentBin?.binHistory[historyIndex - 1]
                                ?.date)) && (
                          <div className="bin_history-date-pill">
                            {moment(history?.date, "DD-MM-YYYY")?.format(
                              "ddd DD/MM/YYYY",
                            )}{" "}
                          </div>
                        )}
                        <HistoryTile
                          history={history}
                          setSelectedHistory={() => setSelectedHistory(history)}
                        />
                      </div>
                    );
                  })}
                </div>
              </AccordionItem>
            );
          })}
        </div>
      )}

      <BottomModal
        isOpen={actionModal}
        backdrop
        className="bin_customClass"
        style={{}}
        onClose={() => {
          setActionModal(false);
        }}
      >
        <div className="bin_dashboardActions">
          <div className="bin_dashboardTitle">Bin History - Actions</div>
          <div className="">
            <Button
              className="mb-2"
              onClick={() => {
                /* handle PDF download */
              }}
            >
              Download PDF Report
            </Button>
            <Button
              className=""
              onClick={() => {
                /* handle CSV download */
              }}
            >
              Download CSV Report
            </Button>
          </div>
        </div>
      </BottomModal>

      <BinHistoryDetail
        bin={bin}
        historyDetail={selectedHistory}
        onClose={() => setSelectedHistory(null)}
      />
    </div>
  );
};

export default BinHistory;
