import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SteelServLogo } from "../../assets/images/index";
import { Button, Checkbox, InputField } from '../../components';
import { setAccessToken } from '../../services/accessToken';
import { AuthContext } from '../../services/auth';
import { UserContext } from '../../services/userContext';
import { emailValidation, passwordValidation } from '../../utils/helper';
import DisplayInstallationInstructions from './displayInstallationInstructions';
import "./styles.scss";
import DisplayBinWithText from './DisplayBinWithText';

const Login = () => {
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState({
        emailErrorMsg: '',
        passwordErrorMsg: '',
        general: '',
    });

    const forgotPassword = () => {
        navigate('/forgot-password');
    };

    useEffect(() => {
        const savedEmail = localStorage.getItem('rememberEmail');
        const savedPass = localStorage.getItem('rememberPassword');
        if (savedEmail) {
            setEmail(savedEmail);
            setPassword(savedPass)
            setIsChecked(true); // Automatically check "Remember Me" if we have saved email
        }
    }, []);

    const handleCheckboxChange = () => setIsChecked(!isChecked);

    const { login } = useContext(AuthContext);
    const { setDataWhenLogging } = useContext(UserContext);

    const handleButtonClick = async (e) => {
        e.preventDefault();
        setLoading(true);
        const emailErrorMsg = emailValidation(email);
        let passwordErrorMsg = passwordValidation(password);
        setErrorMsg({ emailErrorMsg, passwordErrorMsg });
        if (emailErrorMsg || passwordErrorMsg) {
            setLoading(false);
            return;
        }
        try {
            const res = await login({ email, password, validateAccessToBins: true });
            if (res.data) {

                if (isChecked) {
                    localStorage.setItem('rememberEmail', email);
                    localStorage.setItem('rememberPassword', password);
                } else {
                    localStorage.removeItem('rememberEmail');
                    localStorage.removeItem('rememberPassword');
                }

                let userInfo = {
                    first_name: res.data.first_name,
                    last_name: res.data.last_name,
                    email: res.data.email,
                    accessToken: res.data.accessToken,
                    added_by: res.data.added_by,
                    pin_number: res.data.pin_number,
                    type: res.data.type,
                    refreshToken: res.data.refreshToken
                };
                if (userInfo.status === 'Invited')
                    return navigate('/register', { state: { userInfo } });

                const [token, departments] = userInfo.accessToken?.split('||');
                userInfo.departments = departments;
                setAccessToken(token);
                localStorage.setItem('accessToken', token);
                localStorage.setItem('refreshToken', userInfo.refreshToken);
                await setDataWhenLogging(userInfo);
                navigate('/dashboard');
            }
        } catch (error) {
            setErrorMsg({ general: error.message });
        }
        setLoading(false);
    };

    const disableButton = !email?.length || !password.length
    return (
        <div className='d-flex bin_open-page-content  d-flex flex-column'>
            <div className='bin_login-logo'>
                <img
                    src={SteelServLogo}
                    className='bin_logo'
                    alt="Eye"
                />
            </div>
            <DisplayBinWithText binSize={40} text='Login to Bins App'/>
            <div>
                <InputField
                    label="Username"
                    labelFont="Username"
                    value={email}
                    onChange={(value) => setEmail(value)}
                    className=""
                    style={{}}
                    isError={errorMsg.emailErrorMsg ? true : false}
                    type="text"
                    isDisabled={false}
                />
                <p className="bin_error-msg">{errorMsg.emailErrorMsg}</p>
                <InputField
                    label="Password"
                    type={"password"}
                    password
                    value={password}
                    onChange={(value) => setPassword(value)}
                    isError={errorMsg.passwordErrorMsg || errorMsg.general  ? true : false}
                    className=""
                    style={{}}
                    isDisabled={false}
                />
                <p className="bin_error-msg">{errorMsg.passwordErrorMsg}</p>
                {errorMsg.general && <p className="bin_error-msg">{errorMsg.general}</p>}

                <div className='d-flex bin_login_checkbox mt-4 justify-content-between align-items-end'>
                    <div className=" d-flex align-items-center">
                        <Checkbox
                            label="Remember Me"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            className=""
                            style={{}}
                            isDisabled={false}
                        />
                    </div>
                    <div className="forgot-text" onClick={forgotPassword}>
                        Forgot password?
                    </div>
                </div>
            </div>
            <DisplayInstallationInstructions /> 
            <div style={{marginTop: 'auto'}}>
                <Button
                    onClick={handleButtonClick}
                    loading={isLoading}
                    className={disableButton ? 'login-button-disabled' : 'login-button'}
                    isDisabled={disableButton}
                >
                  <DisplayBinWithText binSize={40} text='Login to Bins Mobile App'/>
                </Button>
            </div>

        </div >
    );
};

export default Login;
