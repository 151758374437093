import { ArcElement, Chart as ChartJS, } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import "./styles.scss"
import { BinColor } from '../../../../../assets/images';

const shadowPlugin = {
  id: 'shadow',
  afterDatasetsDraw: function (chart, easing) {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          // Draw the shadow here
          ctx.fillStyle = 'rgba(0, 0, 0, 0.15)'; // This sets the shadow color
          const { startAngle, endAngle, innerRadius, outerRadius, x, y } = element;
          let shadowOffsetX = 1;
          let shadowOffsetY = 0;
          let shadowBlur = 0;

          // Save the context state
          ctx.save();
          // Apply the shadow
          ctx.shadowOffsetX = shadowOffsetX;
          ctx.shadowOffsetY = shadowOffsetY;
          ctx.shadowBlur = shadowBlur;
          ctx.shadowColor = 'rgba(0,0,0,0.25)';

          // Create the arc with the shadow
          ctx.beginPath();
          ctx.arc(x, y, outerRadius - 13, startAngle, endAngle);
          ctx.arc(x, y, innerRadius, endAngle, startAngle, true);
          ctx.closePath();
          ctx.fill();

          // Restore the context state
          ctx.restore();
        });
      }
    });
  }
};

ChartJS.register(ArcElement, shadowPlugin);

const colors = [
  "#626BBC",
  "#F29F1A",
  "#00C896",
  "#FF5733",
  "#D1A309",
  "#33A3FF",
  "#7D3C98",
  "#FF3366",
  "#14A76C",
  "#FFBD33",
  "#6666FF",
  "#FF5733",
  "#A309D1",
  "#33A3FF",
  "#98A63C",
  "#3366FF",
  "#6C14A7",
  "#FFBD33",
  "#33A3FF",
  "#D133A3",
  "#4C7D61",
  "#A733FF",
  "#FF3333",
  "#D9A309",
  "#33A3FF",
  "#33FFA7",
  "#A733D9",
  "#33A3FF",
  "#FF5733"
];

const PieChart = ({ data }) => {

  const plugins = [{
    id: 'textInsideDoughnut',
    beforeDraw: function (chart) {
      let width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      let fontSize = 2;
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "middle";

      const sumOfLoadCounts = data.loadSummary.reduce((accumulator, summary) => accumulator + summary.loadCount, 0);

      let text = `${sumOfLoadCounts}`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }];

  // Extract the bin names and load counts from the data
  const binNames = data.loadSummary.map(summary => `Bin ${summary.binName}`);
  const loadCounts = data.loadSummary.map(summary => summary.loadCount);

  const totalLoadCount = data.loadSummary.reduce((total, timeline) => total + (timeline.loadCount ?? 0), 0);
  const isTotalLoadCountZero = totalLoadCount === 0;

  // Setup the chart data
  const chartData = {
    labels: binNames,
    datasets: [
      {
        label: 'Load Summary',
        data: isTotalLoadCountZero ? [1] : loadCounts,
        backgroundColor: isTotalLoadCountZero ? ['rgba(211, 211, 211, 0.5)'] : colors,
        hoverBackgroundColor: colors,
        borderWidth: 0,
      }
    ]
  };


  const options = {
    // maintainAspectRatio: false,
    responsive: true,
    maintainAspectRatio: true,
    cutout: '60%', // Use 'cutout' instead of 'cutoutPercentage' for Chart.js 3
    plugins: {
      legend: {
        display: false // Disable legend if you don't want it
      },
      tooltip: {
        enabled: true // Enable tooltip if needed
      }
    }
  };

  return  <div key={JSON.stringify(data)} className=''>

    <div style={{ maxHeight: "100px" }} >
      <Doughnut data={chartData} options={options} plugins={plugins} />
    </div>

    <div className='mt-5'>
      {data?.loadSummary?.map((load, index) => {
        return <div className='bin_legend-pie-text d-flex align-items-center'>
          <BinColor
            width={31.95}
            height={30.5}
            color={colors[index]}
            className="mr-4"
          />

          Bin {load.binName} - <span className='bin_legend-pie-bold-text'>{load.loadCount} Loads</span>
        </div>
      })}
    </div>
  </div>;
};

export default PieChart;
