import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Button, Header, toast } from "../../components";
import { UserContext } from '../../services/userContext';
import "./styles.scss";
import { API_METHODS, ENDPOINTS } from '../../utils/constants';

const Profile = () => {
    const { userInfo, ApiHandler } = useContext(UserContext)
    const navigate = useNavigate();

    const handleButtonClick1 = () => {
        navigate('/edit-profile');
    };
    const handleButtonClick2 = () => {
        navigate('/edit-password');
    };
    const handleLogoutButtonClick = async (e) => {
        e.preventDefault();

        try {
            const data = {
                isPreStartCheck: false,
            };
            const response = await ApiHandler({
                reqParam: data,
                method: API_METHODS.POST,
                endPoint: ENDPOINTS.logout,
            });

            if (response.status === 200) {
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            }
        } catch (error) {
            toast.error(error?.message);
        }
    };

    return (
        <div className=' d-flex justify-content-between flex-column'>
            <div className='pl-4 pb-1'>
                <Header
                    label={`Hello ${userInfo?.first_name ?? ""}!`}
                    className={"bin_profile-header"}
                />
            </div>

            <div className='bin_page-content pb-2'>
                <div className='d-flex h-100 flex-column justify-content-between'>
                    <div className='px-4 d-flex h-100 flex-column justify-content-between'>
                        <div className='flex-1 mt-5' >
                            <Row className='mt-3 ms-5'>
                                <Col className='col-4 profile-label'> Name :</Col>
                                <Col className='col-6 profile-value'> {userInfo?.first_name ?? ""} {userInfo?.last_name ?? ""}</Col>
                            </Row>
                            <Row className='mt-3 ms-5'>
                                <Col className='col-4 profile-label'>Email :</Col>
                                <Col className='col-6 profile-value'> {userInfo?.email ?? ""}</Col>
                            </Row>
                            <Row className='mt-3 ms-5'>
                                <Col className='col-4 profile-label'>User Type : </Col>
                                <Col className='col-6 profile-value'> {userInfo?.type ?? ""}</Col>
                            </Row>
                        </div>
                    </div>
                    <div className='d-flex flex-column px-4 justify-content-between'>
                        <div className='w-100'>
                            <Button
                                onClick={handleButtonClick1}
                                className={""}
                            >
                                <div>Edit Profile</div>
                            </Button>
                            <Button
                                onClick={handleButtonClick2}
                                className={"mt-2 logout-buffer"}
                            >
                                <div>Change Password</div>
                            </Button>

                            <Button
                                onClick={handleLogoutButtonClick}
                                className={"mt-2"}
                            >
                                <div>Logout</div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
