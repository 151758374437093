import React from 'react';
import KeyValueLabels from './KeyValueLabels.js';
import "./styles.scss";
import { LabelWithLine } from '../../../../../components/index.js';
import { calculateDuration, getHistoryStatusLabels, getHistoryTypeLabels } from '../../../../../utils/binHelper.jsx';
import moment from 'moment';

const getLastSeenFromJobEvents = (events = []) => {
    // due to disconnection there may be possiblity that only one event from middle come and it has no start time, 
    // so if no end time is found, we have to return zone of last event having start time.
    // it is also handling the case when tracking bin, only one event have been come with type transitToPickup and have only start time 
    let isEndTimeFound = [...events]?.reverse()?.find((event) => event?.endTime);
    if (isEndTimeFound?.endTime) {
        return isEndTimeFound?.zone || isEndTimeFound?.location || "-"
    }
    let isStartTimeFound = [...events]?.reverse()?.find((event) => event?.startTime);
    if (isStartTimeFound?.startTime) {
        return isStartTimeFound?.zone || isStartTimeFound?.location || "-"
    }
    return "-"
}

export const getPickupTotalDuration = (pickup) =>
  pickup?.events[0]
    ? calculateDuration(
        pickup.events[0].startTime,
        pickup.endTime ?? new Date(),
      ).time
    : "-";

const calculateCycleDuration = (binPickup) => {
  if (!binPickup.events) return "-";
  const cycleStartTimeEvent = binPickup.events.find((event) => event.startTime);
  let cycleEndTime = moment(binPickup.endTime ?? new Date());

  if (!cycleStartTimeEvent?.startTime) return "-";

  const cycleStartTime = moment(cycleStartTimeEvent.startTime);

  const formattedDuration = getPickupTotalDuration(binPickup);
  const startDate = cycleStartTime.format("DD/MM/YYYY");
  const startTime = cycleStartTime.format("hh:mm A");
  const endTime = cycleEndTime.format("hh:mm A");

  return `${startDate}, ${startTime} - ${
    binPickup.endTime ? endTime : "Ongoing"
  } ${binPickup.endTime ? `(${formattedDuration})` : ""}`;
};


const SubJob = ({ historyDetail = {} }) => {

    return (
        <>
            <LabelWithLine title={"Job Details"} />
            <KeyValueLabels value={historyDetail?.truckName} title={"Truck:"} />
            <KeyValueLabels value={(historyDetail?.operator?.firstName ?? "-") + " " + (historyDetail?.operator?.lastName ?? "")} title={"Operator:"} />
            <KeyValueLabels value={getLastSeenFromJobEvents(historyDetail?.events)} title={"Last Seen:"} />
            <KeyValueLabels value={getHistoryTypeLabels(historyDetail?.type)} title={"Job Type:"} />
            <KeyValueLabels value={getHistoryStatusLabels(historyDetail?.status)} title={"Job Status:"} />
            <KeyValueLabels value={calculateCycleDuration(historyDetail)} title={"Cycle Duration:"} />
        </>
    );
};

export default SubJob;

