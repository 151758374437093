import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import "./styles.scss";
import moment from 'moment';
import { colors } from '../../../../../utils/binHelper';

const MonthlyProgressBars = ({ data, currentDate, index }) => {
  const { loadCountsPerDay } = data;
  const maxValue = Math.max(...Object.values(loadCountsPerDay));

  return (
    <div className='bin_monthly-bars-container '>
      <div className='bin_month-title '>Weekly</div>
      <div className='bin_dashboard-title'>
        Bin {data.binName} Loads:
        <span className='bin_dashboard-bold-title'> {Object.values(loadCountsPerDay).reduce((a, b) => a + b, 0)}  (LDS) </span>
      </div>

      {Object.entries(loadCountsPerDay).map(([day, value], loadIndex) => (
        <div>
          {moment(day).format('ddd') === "Mon" || loadIndex == 0 ? (
            <em className='bin_month-title'>
              Week {loadIndex == 0 ? 1 : moment(day).week() - moment(day).startOf('month').week() + 1}
            </em>
          ) : (
            <></>
          )}

          <div key={day} className={`pt-1 d-flex position-relative bin_weekly-progress-bar align-items-center`}>
            <div className={`${moment(day).isSame(moment(), 'day') ? "bin_selected-weekly-progress-bar" : ""}`}></div>

            <div className='bin_monthly-day-text'>{moment(day).format('ddd DD')}</div>
            <div className='flex-1 mx-3' >
              <ProgressBar
                now={(value / maxValue) * 100}
                label={value}
                style={{ backgroundColor: colors[index] + "41" }}
              >
                <ProgressBar
                  now={(value / maxValue) * 100}
                  style={{ backgroundColor: colors[index] }}
                />
              </ProgressBar>
            </div>
            <div className='bin_monthly-load-text'>{value} </div>
          </div>

        </div>
      ))}
    </div>
  );
};

export default MonthlyProgressBars;
