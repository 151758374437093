import React from 'react';

const BinInfoTile = ({ icon = <></>, value = 0, label = "", subValue = "" }) => (
    <div className='d-flex'>
        <div className="row m-0 p-0 w-100 ">
            <div className='col-3 ml-0 pl-0 d-flex justify-content-start w-100'>
                {icon}
            </div>
            <div className='col-9 d-flex flex-column' style={{  padding: "0px", margin: "0px" }}>
                <div className='bin_page-level-label'>
                    {label}
                </div>
                <div style={{wordBreak : "break-all"}} className="bin_page-level-value">
                    {value} <br/> {subValue}
                </div>
            </div>

        </div>
    </div>
);

export default BinInfoTile;
