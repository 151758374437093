import * as React from "react";
const DefaultMarker = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25.893}
    height={36.764}
    viewBox="0 0 25.893 36.764"
    {...props}
  >
    <g
      id="Group_19893"
      data-name="Group 19893"
      transform="translate(2798.928 8071)"
    >
      <path
        id="Path_15887"
        data-name="Path 15887"
        d="M163.018,48.949A11.947,11.947,0,0,1,174.965,60.9a12.393,12.393,0,0,1-2.042,7.426c-1.621,2.594-9.9,14.628-9.9,14.628s-8.283-12.034-9.9-14.628a12.393,12.393,0,0,1-2.042-7.426A11.947,11.947,0,0,1,163.018,48.949Z"
        transform="translate(-2949 -8118.949)"
        stroke={props.stroke ?? "black"}
        fill={props.fill ?? "white"}
        stroke-width="2"
      />
      <path
        id="Path_15886"
        data-name="Path 15886"
        d="M162.365,56h.014a4.373,4.373,0,0,1,.013,8.745h-.039A4.373,4.373,0,0,1,162.365,56Z"
        transform="translate(-2948.355 -8118.292)"
        fill="#fff"
        stroke={props.stroke ?? "black"}
        stroke-width="2"
      />
    </g>
  </svg>
);
export default DefaultMarker;
