import React from 'react';
import "./styles.scss";

const LabelWithLine = ({
    title
}) => {

    return (
        <div className='d-flex align-items-end my-4'>
            <div className='bin_label_with_line-title'>{title}</div>
            <div className='flex-1 bin_label_with_line-border '></div>
        </div>
    );
};

export default LabelWithLine;
