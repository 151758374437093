import React, { useState } from 'react';
import './styles.scss';

const TextArea = ({
    placeholder,
    value,
    onChange,
    className,
    style,
    type,
    onFocus,
    onBlur,
    isDisabled,
    numOfLines
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleFocus = (e) => {
        setIsFocused(true);
        onFocus && onFocus(e);
    };

    const handleBlur = (e) => {
        setIsFocused(false);
        onBlur && onBlur(e);
    };

    return (
        <div className='bin_outside-text-area' >
            <div className='w-100 position-relative '>
                <div className={`w-100 bin_text-area  ${className}`}>
                    <textarea
                        type={showPassword ? "text" : type || 'text'}
                        placeholder={placeholder ?? ''}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        className={`bin_text-area-inside ${className}`}
                        style={{
                            ...style,
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        rows={numOfLines}
                        disabled={isDisabled}
                    />
                </div>
           
            </div>
        </div >
    );
};

export default TextArea;
