import "./styles.scss";
import { BIN_STATUS, getCommonBinStatus } from "../../../../utils/binHelper";
import { MissingAlert, PickupAlert } from "../../../../assets/images";

const BinTitle = ({ bin, displayHistoricalStatus }) => {
  return (
    <div className="bin_page-header-content">
      {displayHistoricalStatus ? null : (
        // Only display fron icons if display commonBinStatus (not on Bin History page)
        <>
          {getCommonBinStatus(bin) === BIN_STATUS.BIN_MISSING && (
            <MissingAlert className="mr-3" />
          )}
          {getCommonBinStatus(bin) === BIN_STATUS.PICK_UP_REQUESTED && (
            <PickupAlert className="mr-3" />
          )}
        </>
      )}

      <span>Bin {bin.name}</span>
    </div>
  );
};

export default BinTitle;
