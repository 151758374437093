import * as React from "react"
const PickupIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.168}
     viewBox="0 0 19.168 27.211"
    height={27.211}
    {...props}
  >
    <g stroke="#6e8173" strokeWidth={1.6} data-name="Group 57962">
      <path
        fill="#cedbd1"
        d="M9.584.8a8.784 8.784 0 0 1 8.784 8.784 9.113 9.113 0 0 1-1.5 5.46C15.676 16.951 9.585 25.8 9.585 25.8s-6.09-8.848-7.283-10.756a9.113 9.113 0 0 1-1.5-5.46A8.784 8.784 0 0 1 9.584.8Z"
        data-name="Path 15887"
      />
      <path
        fill="#fff"
        d="M9.579 6.467h.01a3.216 3.216 0 0 1 .01 6.431h-.03a3.216 3.216 0 0 1 .01-6.431Z"
        data-name="Path 15886"
      />
    </g>
  </svg>
)
export default PickupIcon
